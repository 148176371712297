/*-------------------------------------------------------------------
[Table of contents]
1. Top bar / . topbar
2. Logo / .logo
3. Main header / .mainheader
4. Menu button  / .menu-button, .closebutton
5. Main menu  / .mainmenu
6 Menu level / .menulevel
7. Main menu item / .mainmenu-item
8. Sticky menu / stuck
-------------------------------------------------------------------*/
.header-float {
  position: absolute;
  z-index: 10;
  width: 100%;
  .menu-button {
    color: $white;
  }
  .mainmenu-item {
    >.menulevel {
      left: 0;
      .menulevel {
        left: -100%;
      }
    }
    >a {
      color: #b1b1b1;
    }
    &:hover {
      >a{
        color: $white;
      }
    }
  }
  #logo-1 {
    display: none;
  }
  .menulevel {
    box-shadow: 0px 1px 7px 0px rgba($black, 0.3);

  }
}

// Main header float
.sticked-menu-wrapper {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 50px;
  .menu-button {
    color: $white;
  }
}


// Top bar
.topbar {
  color: #d3d3d3;
  height: 50px;
  background: $accent-color;
  .general-info {
    float: left;
    .info-box {
      display: inline-block;
      line-height: 50px;
      padding-right: 49px;
      .info-icon {
        font-size: 15px;
      }
      .info-content {
        font-size: 13px;
      }
    }
  }
  &-canvas {
    height: 100px;
  }
  &-invert {
    background: $white;
    color: $accent-color;
    .social {
      .social-item {
        a {
          color: $accent-color;
        }
      }
    }
  }
  &-mobile {
    display: none;
  }
}

//Logo
.logo{
  line-height: 100%;
  max-width: 100%;
  height: 100px;
  display: table;
  float: left;
  a {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
  }
}
//Main header
.mainheader {
  height: 100px;
  &.small-mainheader {
    height: 70px;
    .logo {
      height : 70px;
    }
    .mainmenu {
      .mainmenu-item {
        >a {
          line-height: 70px;
        }
      }
      &.default-menu {
        height: 70px;
      }
      .mainmenu-item>.menulevel {
        top: 70px;
      }
    }

  }
  &-centered {
    text-align: center;
    .menu-wrapper {
      display: inline-block;
    }
  }
}

//Menu
//Menu button
.menu-button, .close-button {
  cursor: pointer;
  display: none;
}
.menu-button {
  font-size:  30px;
  margin-top: 30px;
  float: right;
  color: $accent-color;
}
.close-button {
  font-size: 20px;
  color: $accent-color;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 20px;
  z-index: 101;
  transition-delay: 0.5s;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $theme-color;
  }
}

.accent-bg {
  .menu-button {
    margin-right: 22px;
  }
}
//Main menu
.menu-wrapper {
  text-align: left;
}
.menu-wrapper-float {
    float: right;
}
.mainmenu {
  li {
    position: relative;
    a {
      line-height: 100px;
      color: $grey-1;
      cursor: pointer;
      &:hover {
        color: $theme-color;
      }
    }
  }
  &.default-menu {
    height: 100px;
  }
  // menu dopdown

  li:hover {
    >.menulevel {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}
.menu-color-grey {
  .mainmenu {
    li a {
      color: $grey-2;
    }
  }
}
//Menu level
.stuck .menulevel,  .menulevel {
  width: 220px;
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 1px 7px 0px rgba($black, 0.1);
  background: $white;
  transition: all 0.25s ease-in-out;
  transform: translateY(20px);
}

// Main menu item
.mainmenu-item {
  color: $grey-1;
  padding: 0 22px;
  display: inline-block;
  a {
    font-size: 15px;
    font-weight: $bold;
  }
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  &:hover {
    color: $theme-color;
  }
  >.menulevel {
    top: 100px;
    left: 0;
    margin-left: -10px;
    li {
      a , h3{
        padding: 0 28px 0 34px;
        display: block;
        font-size: 14px;
        line-height: 52px;
        &:hover {
          color: $white;
          background-color: $theme-color;
          box-shadow: 0px 1px 7px 0px rgba($black, 0.1);
        }
      }
      &.haschild  {
        >a , >h3 {
          &:after {
            content:"\f105";
            font-family: FontAwesome;
            font-size: 14px;
            line-height: 52px;
            color: $grey-1;
            position: absolute;
            top: 0;
            right: 20px;
            padding: 0 10px;
          }
          &:hover:after {
            color: $white;
          }
        }
      }
    }
    .menulevel {
      top: 0;
      right: -100%;
      padding-bottom: 18px;
      overflow: hidden;
    }
  }
}



// Menu responsive
.mainheader-wrapper {
  position: relative;
}
#resmenufloat {
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 100;
}

//Sticky menu
.stuck {
  width: 100%;
  position:fixed;
  top:0;
  z-index: 100;
  background: $white;
  &-nonebg {
    background: none;
  }
  .logo {
    height: 80px;
    img {
      width: 60%;
    }
  }
  .menu-button {
    font-size: 20px;
  }
  .mainheader {
    background: $white;
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
  }
  .mainheader, .mainmenu {
    height: 80px;
    >li{
      >a, >h3 {
        color: $grey-1;
        line-height: 80px;
      }
      &:hover {
        >a, >h3  {
          color: $theme-color;
        }
      }
    }
    &.small-mainheader {
      height: 70px;
    }
  }


  .mainmenu-item {
    color: $grey-1;
    &:hover {
      color: $theme-color;
    }
    >.menulevel {
      top: 80px;
      li {
        >a , >h3{
          color: $grey-1;
        }
        &:hover {
          background-color: $theme-color;
          box-shadow: 0px 1px 7px 0px rgba($black, 0.1);
          >a, >h3 {
            color: $white;
          }
        }
      }
    }
  }
}



//Responsive
@include respond-to('medium-desktop') {
  .mainmenu-item > .menulevel .menulevel {
    left: -100%;
  }
  .mainmenu-2 {
    .menuoffset {
      max-width: 930px;
    }
  }
}
@include respond-to('small-destop') {
  .mainheader.small-mainheader {
    .menu-wrapper {
      top: 70px;
    }
    .mainmenu.default-menu {
      height: auto;
      top: 70px;
    }
  }
  .header-float .menu-wrapper, .menu-wrapper {
    box-shadow: 0px 1px 7px 0px rgba($black, 0.1);
    width: 100%;
    position: absolute;
    left: 0;
    top: 80px;
    z-index: 990;
    &-float {
      float: none;
    }
    .default-menu.mainmenu {
      display: none;
      z-index: 100;
      background: $white;
      overflow: hidden;
      height: auto;
      width: 100%;
      transition: none;
      >li:first-child {
        border: none;
      }
      li {
        position: relative;
        overflow: hidden;
        border-top: 1px solid #eaeaea;
        a {
          display: block;
          &:hover {
            background-color: $theme-color;
            box-shadow: 0px 1px 7px 0px rgba($black, 0.1);
            color: $white;
          }
        }
        &.haschild {
          >a {
            span{
              position: relative;
              &:after {
                content: "\f107";
                font-family: FontAwesome;
                font-size: 14px;
                line-height: 60px;
                color: $grey-1;
                position: absolute;
                top: 0;
                right: 20px;
                padding: 0 0 0 10px;
                background: none;
              }

            }
            &:hover:after {
              color: $white;

            }
          }
          >a.ropen, >h3.ropen {
            background: $theme-color;
            color: $white;
            span:after {
              content: "\f106";
              color: $white;
            }
          }
        }

      }
      .menulevel {
        position: static;
        margin: 0;
        box-shadow: none;
        width: 100%;
        padding: 0;
        display: none;
        opacity: 1;
        visibility: visible;
        transform: translate(0);
        transition: none;
        li.haschild {
          >a, >h3 {
            &:before {
              line-height: 50px;
            }
          }
        }
      }
    }
    .mainmenu-item {
      color: $grey-1;
      display: block;
      padding: 0;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      a {
        color: $grey-1;
        line-height: 60px;
        &:hover {
          color: $theme-color;
        }
      }

      .menulevel li:hover, .mainmenu{
        a:hover, h3:hover {
          background: $theme-color;
          color: $white;
        }
      }
      >.menulevel {
        li {
          padding: 0;
          a , h3 {
            display: block;
          }
          >.menulevel {
            a {
              padding: 0 30px 0 50px
            }
          }
        }

      }
    }
  }
  .menuoffset {
    max-width: 750px;
    padding: 0 15px;
    margin: 0 auto;
    display: block;
  }
  .menu {
    margin: 0 auto;
  }
  .stuck {
    .mainmenu {
      li a {
        line-height: 60px;
      }
    }
  }
  .mainmenu-2 {
    .menuoffset {
      max-width: 730px;
    }
  }
  .menu-button {
    display: block;
  }
  .topbar {
    height: auto;
    padding: 15px 0;
    text-align: center;
    .general-info {
      float: none;
      .info-box {
        line-height: 24px;
        padding: 0;
        display: block;
      }
    }
    .socials {
      line-height: 24px;
      text-align: center;
      float: none;
    }
    &-canvas {
      height: 170px;
    }
    &-desktop {
      display: none;
    }
    &-mobile {
      display: block;
    }
  }
  //logo
  .logo {
    height: 80px;
    img {
      width: 60%;
    }
  }
  // Menu
  .sticked-menu-wrapper {
    top: 130px;
  }
  .mainmenu li a , .mainmenu li h3 {
    line-height: 80px;
  }
  .mainheader, .default-menu.mainmenu {
    height: 80px;
  }
  .menu-button {
    font-size: 20px;
  }
  .stuck.stickymenu-float, .stickymenu-float {
    .mainheader .mainmenu-item  {
      &:hover {
        a {
          color: $white;
        }
      }
      a {
        color: $grey-1;
      }
    }
  }
  .sticked-menu-wrapper {
    .stuck-nonebg {
      background: $accent-color;
      .mainheader {
        background: none;
      }
      .mainmenu {
        height: auto;
        padding: 0;
        margin: 0 auto;
      }
    }
    .stuck.stickymenu-float, .stickymenu-float {
      .mainheader {
        .logo {
          margin: 0;
        }
        .menulevel {
          background: $white;
        }
        .mainmenu-item {
          &:hover a{
            color: $grey-1;
          }
          &:hover > a {
            background: $theme-color;
            color: $white;
          }
          &:last-child {
            padding: 0;
          }
          li a:hover {
            background: $theme-color;
            color: $white;
          }
        }
      }
      .accent-bg {
        .menu-button {
          margin-right: 0;
        }
      }
    }

  }
}

@include respond-to('medium-mobile') {
  .header-float {
    position: static;
    background: $accent-color;
    .container-fluid.offset {
      padding: 0 15px;
    }
  }
}
@include respond-to('small-mobile') {
  .stuck .mainheader, .mainheader, .mainheader.small-mainheader {
    height: auto;
  }
  .mainheader .socials.float-right {
    float: left;
    margin-bottom: 20px;
    .social-item-1 {
      line-height: 1;
    }
  }
  .header-float .menu-wrapper {
    top: 130px;
  }
  .mainheader.small-mainheader .menu-wrapper {
    top: 120px;
  }
}


