.blog-list-wrapper {
  margin: 30px 0 80px;
}

.blogs {
  padding: 60px 0 45px;
  &-1 {
    padding: 65px 0 65px;
  }
}

.blog {
  margin-bottom: 55px;
  .blog-content {
    .section-title {
      margin: 25px 0 0;
      a {
        color: $grey-1;
      }
    }
    .meta {
      line-height: 45px;
    }
    .des {
      padding-right: 75px;
      margin-bottom: 20px;
    }
  }
  &-1 {
    margin-bottom: 30px;
    .blog-img {
      padding: 0;
    }
    .blog-info {
      .name {
        font-size: 16px;
        font-weight: $bold;
        line-height: 1.375;
        margin: 15px 0 10px;
        a {
          color: $grey-1;
        }
      }
      .meta {
        line-height: 1.69;
        color: $grey-4;
        font-weight: 400;
        &:before {
          content: '\f017';
          font-family: FontAwesome;
          font-size: 13px;
          color: $accent-color;
          margin-right: 7px;
        }
      }
    }
  }
}

.blog-img {
  line-height: 0;
  display: block;
  padding-right: 15px;
  img {
    max-width: 100%;
  }
}

// Blog detail content
.blog, .blog-detail-content {
  .section-title {
    margin-bottom: 0;
    a {
      color: $grey-1;
    }
  }
  .meta {
    font-size: 13px;
    line-height: 55px;
    font-weight: $bold;
    color: #999;
    .time {
      &:before {
        content: '\f017';
        font-family: FontAwesome;
        padding-right: 10px;
      }
      &:after {
        content: '|';
        padding: 0 27px 0 12px;
      }
    }
    .author {
      color: #999;
      &:before {
        content: '\f007';
        font-family: FontAwesome;
        padding-right: 10px;
      }
    }
  }
  .des {
    padding-right: 35px;
    margin: 10px 0 30px;
    p {
      color: $grey-4;
      margin-bottom: 15px;
    }
  }
}

// Sub blog
.sub-blog {
  margin-bottom: 30px;
  .title {
    margin: 0 0 12px 0;
    a {
      font-size: 18px;
      font-weight: $bold;
      line-height: 1.44;
      color: $grey-1;
    }
  }
  .des {
    line-height: 1.57;
    color: $grey-4;
    margin-bottom: 20px;
  }
}

// More media
.moremedia {
  padding: 0 0 30px;
  border-bottom: 1px solid #e5e5e5;
  .tag, .social {
    display: inline-block;
    color: #052035;
    font-size: 14px;
    line-height: 1.57;

  }
  .tag {
    float: left;
    a {
      color: #888;
      font-size: 13px;
      line-height: 1.69;
    }
  }
  .social {
    float: right;
    .social-item {
      display: inline-block;
      line-height: 1;
      padding: 8px;
      &:first-child {
        padding-left: 16px;
      }
      a {
        color: $grey-1;
      }
    }

  }
}

// Related blogs
.related-blogs {
  padding: 35px 0 40px;
  .next-blog {
    text-align: right;
  }
  .auprevbtn, .aunextbtn {
    display: inline-block;
    padding-bottom: 5px;
    font-weight: 400;
  }
  .auprevbtn:before {
    content: "\f060";
    font-family: FontAwesome;
    padding-right: 10px;
  }
  .aunextbtn:after {
    content: "\f061";
    font-family: FontAwesome;
    padding-left: 10px;
  }
  .blog-name {
    line-height: 1.57;
    a {
      color: $grey-1;
      font-size: 14px;
    }

  }
}