// Class helper mixins
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Mediamixins
$breakpoints: (
        'small-mobile':  ( max-width:  320px ),
        'medium-mobile':  ( max-width:  479px ),
        'small-tablet':  ( max-width:  768px ),
        'medium-tablet': ( max-width:  991px ),
        'small-destop' : ( max-width:  1224px ),
        'medium-desktop': (max-width: 1367px),
        'desktop':  ( max-width: 1199px ),
        'desktop-up':  ( min-width: 1200px )
) !default;

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}