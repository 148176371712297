.aubtn{
  display: inline-block;
  background: transparent;
  transition: all 0.3s ease-in-out;
  border: none;
  box-shadow: none;
  cursor: pointer;

  &-primary {
    line-height: 50px;
    font-weight: $bold;
    text-transform: capitalize;
    color: $white;
    position: relative;
    z-index: 2;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: $theme-color;
    }
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      width: 0;
      height: 100%;
      top: 50%;
      left: 50%;
      background: #fff;
      opacity: 0;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
    &:hover {
      color: $theme-color;
      &:after {
        width: 100%;
        opacity: 1;
      }
    }
    &-invert {
      color: $accent-color;
      &:before {
        background: #e7e7e7;
      }
      &:after {
        background: $theme-color;
      }
      &:hover {
        color: $white;
      }
      &-style-2 {
        &:before {
          background: $white;
        }
      }
    }
    &-style-1 {
      &:hover {
        color: $accent-color;
        &:after {
          background: #e7e7e7;
        }
      }
    }
    &-style-2 {
      &:hover {
        color: $accent-color;
      }
    }
  }
  &-fix {
    line-height: 48px;
    font-weight: $bold;
    width: 50px;
    height: 50px;
    text-align: center;
    color: #999;
    margin-right: 5px;
    padding: 0;
    &.active {
      border: 1px solid $theme-color;
      color: $theme-color;
    }
  }
  &-fix-1 {
    width: 120px;
    text-align: center;
    padding: 0;
  }
  &-fix-2 {
    width: 133px;
    text-align: center;
    padding: 0;
  }
  &-fix-3 {
    width: 150px;
    text-align: center;
    padding: 0;
  }
  &.submit-btn {
    font-weight: $bold;
    border-color: $theme-color;
    input {
      background: none;
      box-shadow: none;
      border: none;
      display: block;
      height: 100%;
      margin-bottom: 0;
    }
  }
  //style general for btn
  &-style-1 {
    border: 1px solid;
    line-height: 48px;
  }
  &-style-2 {
    border: 2px solid;
    line-height: 46px;
  }

  //btn size
  &-size-1 {
    padding: 0 17px;
  }
  &-size-2 {
    padding: 0 22px;
  }
  &-size-3 {
    padding: 0 25px;
  }
  &-size-4 {
    padding: 0 30px;
  }
  &-size-5 {
    padding: 0 35px;
  }
  &-size-6 {
    padding: 0 38px;
  }
  &-size-7 {
    padding: 0 42px;
  }
  &-size-8 {
    padding: 0 50px;
  }

  //color btn
  &-color-1 {
    background: #ededed;
    color: #b4b4b4;
  }
  &-color-2 {
    background: #ededed;
    color: #666;
    &:hover {
      color: #666;
    }
  }
  &-color-3 {
    background: transparent;
    color: #999;
  }
  &-color-4 {
    background: transparent;
    color: $grey-1;
  }
  &-color-5 {
    background: #e7e7e7;
    color: $accent-color;
  }
  &-color-6 {
    background: transparent;
    color: $white;
    border-color: $white;
    &:hover {
      border-color: $theme-color;
    }
  }
  &-color-7 {
    background: $theme-color;
    color: $white;
    &:hover {
      background: $white;
      color: $theme-color;
    }
  }
  &-color-8 {
    background: $white;
    color: #999;

  }
}
.auprevbtn, .aunextbtn {
  font-size: 14px;
  line-height: 1.57;
  font-weight: $bold;
  color: #888;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $theme-color;
  }
}
.aureply {
  font-size: 13px;
  font-weight: $bold;
  color: $theme-color;
  cursor: pointer;
}
.rm {
  line-height: 1.57;
  font-weight: $bold;
  text-transform: capitalize;
  color: $theme-color;
  &:before {
    content: '\f0da';
    font-family: FontAwesome;
    padding-right: 8px;
  }
  &:hover {
    color: $theme-color;
  }
}
.quantity-btn {
  line-height: 50px;
  padding: 0 47px 0 17px;
  background: #ededed;
  display: inline-block;
  position: relative;
  color: $grey-1;
  margin-right: 20px;
  .add, .minus {
    font-size: 13px;
    line-height: 1;
    position: absolute;
    right: 14px;
    color: #999;
    cursor: pointer;
  }
  .add {
    top: 0;
    padding-top: 14px;
  }
  .minus {
    bottom: 0;
    padding-bottom: 14px;
  }
}
@include respond-to('medium-mobile') {
  .aubtn {
    &-fix-3 {
      width: 120px;
      padding: 0;
    }
  }
}