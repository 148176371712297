.shoping-cart-wrapper {
  margin-bottom:  60px;
}
.autable {
  font-weight: $bold;
  margin-bottom: 60px;
  thead{
    color: $accent-color;
    background: #ededed;
    tr th {
      height: 53px;
      line-height: 53px;
      border: none;
    }
  }
  tbody {
    tr:first-child {
      td {
        border: none;
      }
    }
  }
  .product {
    padding: 0 0 0 120px;
    width: 100%;
    text-align: left;
    box-shadow: none;
    margin-bottom: 0;
    img {
      max-height: 100%;
      margin-left: -120px;
      float: left;
    }
  }
  .product-name {
    font-size: 14px;
    line-height: 1.7;
    display: block;
    color: $grey-1;
    margin-top: 40px;

  }
  .price {
    color: #888;
    margin: 0;
  }
  .quantity-btn {
    font-weight: $medium;
    margin-right: 0;
  }
  .total-wrapper {
    width: 100%;
    padding-right: 25px;
    .total {
      float: left;
      color: #888;
    }
    .remove-btn {
      float: right;
      color: #888;
      padding: 10px;
      cursor: pointer;
      margin-top: -10px;
    }
  }
}
.code-form {
  .form-left {
    float: left;
    display: inline-block;
  }
}
.couponcode {
  width: 200px;
  line-height: 50px;
  padding: 0;
  display: inline-block;
  input {
    color: $grey-1;
    width: 100%;
    line-height: 50px;
    height: 50px;
    padding: 0 20px;
    border: none;
    background: none;
  }
  ::-webkit-input-placeholder {
    color:    #b4b4b4;
  }
  :-moz-placeholder {
    color:    #b4b4b4;
    opacity:  1;
  }
  ::-moz-placeholder {
    color:    #b4b4b4;
    opacity:  1;
  }
  :-ms-input-placeholder {
    color:    #b4b4b4;
  }
}
.submit-btn {
  margin-left: 14px;
}
.update-cart {
  float: right;
}
.cart-total-wrapper {
  padding: 80px 0 40px;
  .section-title {
    margin-bottom: 35px;
  }
}
// Cart total
.cart-total {
  padding: 30px 43px 45px;
  border: 5px solid #01aac1;
  .subtotal, .total {
    line-height: 1.86;
    color: $grey-1;
    margin-bottom: 15px;
    font-weight: $bold;
    .number{
      color: $grey-4;
    }
  }
  .services-des {
    color: $grey-4;
  }
  .auform.form-checkout {
    .title {
      font-size: 14px;
      line-height: 1.42;
      font-weight: $bold;
      margin: 20px 0 13px;
      color: $grey-1;
    }
    .select-group {
      display: inline-block;
      width: inherit;
    }
    input, select {
      font-size: 13px;
      height: 40px;
      padding: 0 12px;
      line-height: 40px;
      border: none;
      border-radius: 3px;
      background: none;
      .notchoosen {
        color: $grey-4;
      }

    }
    .select-group, input {
      background: #ededed;
      margin-right: 20px;
    }
    ::-webkit-input-placeholder {
      color:    $grey-4;
    }
    :-moz-placeholder {
      color:    $grey-4;
      opacity:  1;
    }
    ::-moz-placeholder {
      color:    $grey-4;
      opacity:  1;
    }
    :-ms-input-placeholder {
      color:    $grey-4;
    }
    .select-button {
      line-height: 40px;
      right: 13px;
      color: #999;
      z-index: 10;
    }
    .total {
      margin-bottom: 20px;
    }
    .aubtn {
      line-height: 50px;
      font-weight: $bold;
      display: block;
      margin-left: 5px;
      text-align: center;
      background: $accent-color;
      color: $white;
      position: relative;
      .fa-arrow-right {
        padding-left: 10px;
      }
      input {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 50px;
        background: none;
      }
    }
  }

}

//Responsive
@include respond-to('medium-mobile') {
  table.autable {
    //display: none;
    thead {
      font-size: 13px;
    }
    .quantity {
      display: none;
    }
    .product {
      padding-left: 0;
      //display: none;
      img {
        //margin-left: -50px;
        //width: 50%;
        display: none;
      }
    }
    tbody {
      tr {
        td {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
    .product-name {
      font-size: 13px;
      font-weight: $medium;
      margin: 10px 0 0 15px;
    }
    .total-wrapper {
      padding-right: 10px;
      //display: none;
      .remove-btn {
        margin-right: -7px;
      }
    }
  }
  .code-form {
    .form-left {
      float: none;
      margin-bottom: 20px;

    }
  }
  .update-cart {
    float: none;
    margin-left: 0;
  }
}
@include respond-to('small-mobile') {
  table.autable {
    .price-wrapper {
      display: none;
    }
  }
  .code-form {
    .couponcode {
      margin-bottom: 20px;
    }
    .aubtn.submit-btn {
      margin-left: 0;
    }
  }
  .cart-total {
    padding: 15px;
  }
}