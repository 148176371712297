.icon-boxes {
  &-pad-1 {
    padding-top: 40px;
  }
}
.icon-box {
  margin-bottom: 45px;
  .icon-box-content {
    margin-top: 15px;
  }
  .title {
    font-size: 18px;
    font-weight: $bold;
    line-height: 1.22;
    color: $grey-1;
    margin-bottom: 10px;
  }
  .description {
    //line-height
  }

  //modify for icon box

}