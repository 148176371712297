.shop-list-wrapper {
  padding-top: 10px;
  margin-bottom: 60px;
  .pagimation {
    margin-top: 10px;
  }
}
.shop-sidebar {

}
.shop-cate-wrapper {
  padding-bottom: 65px;
  &-1 {
    padding-bottom: 0px;
  }
}
.shop-list {
  .pagination {
    margin-top: 10px;
  }
}
.shop-cate {
  border-left: 5px solid #e4e4e4;
  .shop-cate-item {
    font-size: 18px;
    position: relative;
    &:before {
      content:"";
      width: 5px;
      height: 58px;
      background: #e4e4e4;
      position: absolute;
      top: 0;
      left: -5px;
    }
    a {
      line-height: 1.44;
      padding: 0 24px;
      display: block;
      color: #999;
      transition: all 0.2s ease-in-out;
    }
    &:hover, &.active {
      >a {
        color: $theme-color;
      }
      &:before {
        background: $theme-color;
      }
    }
    .shop-cate-level {
      margin-top: -10px;
      .shop-cate-level-item {
        &:hover, &.active {
          >a {
            color: $theme-color;
          }
          &:before {
            background: $theme-color;
          }
        }
      }
    }
    >a {
      line-height: 52px;
    }
  }
}
.shop-sidebar-banner {
  padding: 25px 65px 35px ;
  .shop-sidebar-banner-content {
    .title {
      font-size: 20px;
      line-height: 1.5;
      font-weight: $bold;
      color: #999;
      margin: 50px 0 30px;
    }
    .aubtn {
      font-weight: $bold;
      border-color: $grey-1;
      &:hover {
        background: $white;
        color: $grey-1;
      }
    }
  }
}
//Rated products
.rated-products {
  margin-top: 55px;
  .section-title {
    margin-bottom: 35px;
  }
  .rated-product {
    padding-left: 113px;
    font-size: 18px;
    line-height: 1.44;
    margin-bottom: 20px;
    img {
      float: left;
      margin-left: -113px;
      box-shadow: 0px 1px 6.65px 0.35px rgba(0, 0, 0, 0.1);
    }
    .product-info {
      padding-top: 10px;
    }
    .name {
      margin-bottom: 0;
      font-size: 18px;
      a {
        color: #888;
      }
      &:hover {
        color: #888;
      }
    }
    .price {
      color: $accent-color;
    }
  }
}

//Products
.product {
  font-size: 18px;
  text-align: center;
  padding-bottom: 30px;
  margin-bottom: 50px;
  box-shadow: 0px 1px 6.65px 0.35px rgba(0, 0, 0, 0.1);
  position: relative;
  img {
    max-width: 100%;
  }
  .sale {
    font-size: 14px;
    font-weight: $bold;
    line-height: 30px;
    padding: 0 13px;
    color: $white;
    background: $theme-color;
    position: absolute;
    top: 55px;
    right: 37px;
  }
  .product-info {
    margin: 16px 0 15px;
    .name {
      font-size: 18px;
    }
  }
  .name a{
    line-height: 1.44;
    font-weight: $bold;
    color: $accent-color;
    &:hover {
      color: $accent-color;
    }
  }
  .price {
    .old-price {
      font-size: 13px;
      color: #7e7e7e;
      text-decoration: line-through
    }
    color: $accent-color;
  }
  .aubtn {
    font-size: 14px;
    font-weight: 700;
    border-color: #999;
    &:hover {
      color: $accent-color;
      border-color: $accent-color;
    }
  }

}
//Ordering
.ordering {
  position: relative;
}
.order-nav {
  margin-bottom: 23px;
}
.result, .current-cate {
  color: $grey-1;
  line-height: 35px;
}
.result {
  float: left;
}
.current-cate {
  text-align: right;
  display: inline-block;
  float: right;
  cursor: pointer;
  &:after {
    content: "\f107";
    font-family: FontAwesome;
    padding-left: 10px;
  }
}
.nav.nav-tabs.order-box {
  width: 200px;
  text-align: right;
  border: 1px solid $grey-4;
  color: $grey-1;
  background: $white;
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 10;
  display: none;
  >li.nav-item.order-item {
    >a.nav-link {
      border: none;
      &:hover {
        background-color: $white;
      }
    }
  }
  li.order-item {
    background: none;
    width: 100%;
    float: none;
    text-align: center;
    padding: 0 ;  margin: 0;

    a {
      color: $grey-1;
      display: block;
      line-height: 35px;
      padding: 10px 15px;
      cursor: pointer;
      margin: 0;
    }
    &.active {
      background: none;
    }
    &:hover {
      background: none;
    }
  }
}
.nav-link {
  display: block;
}

// Responsive
@include respond-to('medium-tablet') {
  .shop-list {
    margin-bottom: 55px;
  }
}