// Font family
$primary-font: 'Noto Sans', sans-serif;
$accent-font: 'Gilda Display', serif;
$sub-font: 'Open Sans', sans-serif;


// Fonts weight
$ultraLight: 100;
$light: 200;
$demi: 300;
$normal: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$black: 800;
$ultraBlack: 900;
$italic: italic;

// Animation
$slow: all 0.5s;
$fast: all 0.3s;

// Color
$theme-color: #01aac1;
$primary-color: #0177d7;
$accent-color: #052035;

$black: #000000;
$white:#fff;
$grey-1: #333;
$grey-2: #555;
$grey-3: #e7e7e7;
$grey-4: #666;
$blue-grey: #f6f6f6;

$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #aad450;
$tumblr: #32506d;
$instagram: #517fa4;
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5B9A68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #EB4823;
$yahoo: #7B0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;