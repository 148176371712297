.stats {
  padding: 58px 0 55px;
  &-pad-1 {
    padding: 0  0 75px;
  }
}
.stat-item {
  padding: 20px 0 20px 100px;
}
.stat-icon {
  margin-left: -98px;
  float: left;
}
.stat-content {
  .stat-number {
    font-size: 36px;
    line-height: 1;
    font-weight: $bold;
    color: $accent-color;
  }
  .title {
    font-size: 13px;
    font-weight: $bold;
    line-height: 1.69;
    color: #999;
    margin: 10px 0 0;
  }
}

//Responsive
@include respond-to('medium-tablet') {
  .stats {
    &-pad-1 {
      padding: 25px 0 80px;
    }
  }
}