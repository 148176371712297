.socials {
  li {
    line-height: 50px;
    display: inline-block;

    a {
      color: #d3d3d3;
      padding: 8px;
      &:last-child {
      }
    }
  }
  .social-item-1 {
    line-height: 100px;
  }

  .menu-button-wrapper {
    line-height: 100px;
    .menu-button {
      float: none;
      font-size: 14px;
      color: #d3d3d3;
      margin: 0;
      padding: 8px;
    }
  }
}
.small-mainheader {
  .socials {
    .social-item-1 {
      line-height: 70px;
      a {
        color: $grey-2;
      }
    }
    .menu-button-wrapper {
      .menu-button {
        color: $grey-2;
      }
    }
  }
}
.stuck {
  .socials {
    .social-item-1 {
      line-height: 80px;
    }
  }
  .small-mainheader {
    .socials {
      .social-item-1 {
        line-height: 70px;
      }
    }
  }
}

@include respond-to('small-destop') {
 .socials {
   .social-item-1 {
     line-height: 80px;
   }
 }
}
@include respond-to("medium-mobile")  {
  .socials {
    li {
      a {
        padding: 4px;
      }
    }
  }
}