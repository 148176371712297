ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
a, a:hover, a:active, a:focus {
  text-decoration: none;
}

//grid
.row, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-sm-4, .col-sm-6, .col-sm-12, .col-xs-12 {
  line-height: 0;
  &.fit-left{
    padding-left: 0;
  }
  &.fit-right {
   padding-right: 0;
  }
  @include respond-to('small-tablet') {
    &.fit-left{
      padding-left: 15px;
    }
    &.fit-right {
      padding-right: 15px;
    }
  }
}
.menuoffset {
  max-width: 1200px;
}
.container-fluid.offset {
  padding: 0 60px;
}

//banner
.banner {
  padding: 75px 0;
  background: rgba($theme-color, 0.8);
  .banner-content {
    font-size: 25px;
    line-height: 1.44;
    color: $white;
    text-align: center;
  }
}
//page title
.pagetitle {
  height: 121px;
  background-size: cover;
  display: table;
  float: left;
  .title {
    font-size: 30px;
    font-weight: $bold;
    color: $white;
    display: table-cell;
    vertical-align: middle;
  }
}
//section title
.section-title {

  &-style-1{
    font-size: 30px;
    font-weight: $bold;
    line-height: 1.5;
    position: relative;

  }
  &-underline {
    //text-decoration: underline;
    display: inline-block;
    border-bottom: 2px solid $white;
    &-1:after {
      position: absolute;
      top: 55px;
      left: 50%;
      transform: translateX(-50%);
      display: inline-block;
      content: '';
      height: 3px;
      width: 80px;
      background: $grey-1;
      text-align: center;
    }

  }
  &-style-2 {
    font-size: 60px;
    font-weight: $bold;
    text-decoration: none;
  }
  &-style-3 {
    font-size: 24px;
  }
  &-style-4 {
    font-size: 18px;
  }
  &-color-grey {
    color: $grey-1;
  }
  &-color-white {
    color: $white;
  }
  &-center{
    text-align: center;
  }
  &-moredes {
    color: #ccc;

  }
  &-uppercase {
    text-transform: uppercase;
  }
  &-no-decoration {
    text-decoration: none;
  }
  &.darker-color {
    color: $accent-color;
  }
}

//preloader
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;
  z-index: 9999
}
#status {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -64px;
  margin-left: -64px;
  width: 128px;
  height: 128px;
  z-index: 9999;
  background: red;
}
//Masonry grid
.grid-width {
  width: 24.7%;
}
.grid-width-1 {
  width: 48.7%;
}
.grid-width-2 {
  width: 50.42%;
}
//Responsive
@include  respond-to("medium-desktop") {
  .menuoffset {
    max-width: 970px;
  }
}
@include  respond-to("medium-tablet") {
  .menuoffset {
    max-width: 750px;
    padding: 0 15px;
    margin: 0 auto;
    display: block;
  }


  .grid-width {
    width: 49.5%;
  }
  .grid-width-1 {
    width: 100%;
  }
  .grid-width-2 {
    width: 100%;
  }
}
@include respond-to("medium-mobile") {
  //width: auto;
  .container-fluid.offset {
    padding: 0 15px;
  }
}
@include respond-to('medium-mobile') {
  .section-title-style-1 {
    font-size: 27px;
  }
  .section-title-style-3 {
    font-size: 18px;
  }
  .section-title-style-4 {
    font-size: 18px;
  }
  .banner {
    padding: 50px 0;
    .banner-content {
      font-size: 18px;
    }
  }
  .pagetitle.pagetitle-style-1 {
    float: none;
    height: auto;
    display: block;
    margin: 20px 0;
    text-align: center;
    .title {
      font-size: 20px;
      display: block;
    }
  }
}
@include respond-to('small-mobile') {
  .grid-width {
    width: 100%;
  }
}