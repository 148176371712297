body {
  font: 14px $primary-font;
  font-weight: $medium;
  color: $grey-2;
  overflow: hidden;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
}

p {
  line-height: 1.57;
}
*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }
.clearfix:before, .clearfix:after { content: ''; display: table; }
.clearfix:after { clear: both; }

input, textarea,select {
  &:focus {
    outline: none;
  }
}
@include respond-to('desktop-up'){
  .container {
    width: 1200px;
  }
}
