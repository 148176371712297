.project-detail-wrapper {
  padding: 5px 0 40px;
}
.project-carousel-nav-wrapper {
  height: 121px;
  display: table;
  float: right;
  .nav {
    display: table-cell;
    vertical-align: middle;
  }
  .aubtn {
    width: 32px;
    height: 32px;
    line-height: 28px;
    text-align: center;
    border: 2px solid #ccc;
    color: #ccc;
    font-size: 13px;
    border-radius: 3px;
  }
}
.project-detail {
  .title {
    font-size: 18px;
    font-weight: $bold;
    line-height: 1.5;
    color: $grey-1;
    margin-bottom: 20px;
  }
}
.project-info-wrapper {
  padding-bottom: 20px;

  .project-infos {
    .info {
      font-size: 15px;
      line-height: 1.6;
      color: $grey-4;
      margin-bottom: 10px;
    }
  }
}
.project-content-wrapper {
  .project-content {
    p {
      font-size: 15px;
      line-height: 1.58;
      color: $grey-4;
      margin-bottom: 30px;
    }
  }
}
.project-imgs {
  margin-top: 3px;
}
.project-detail-img {
  text-align: center;
  margin-bottom: 40px;
  img {
    max-width: 100%;
  }
}
@include respond-to('medium-mobile') {
  .project-carousel-nav-wrapper {
    display: block;
    float: none;
    height: auto;
    margin-bottom: 25px;
    text-align: center;
    .nav {
      display: block;
    }
  }
}