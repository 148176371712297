.auform {
  &.cmt {
    height: 170px;
  }
  input,select, textarea {
    line-height: 48px;
    padding: 0 30px;
    width: 100%;
    height: 50px;
    color: #888;
    border: 1px solid #2c2c2c;
    display: inline-block;
    &.from-width-1 {
      width: 215px;
    }
  }

  input, .select-group,textarea   {
    background: $white;
    margin-bottom: 20px;
  }
  .select-group {
    width: 100%;
    position: relative;
    background: $white;
  }
  select {
    color: #4a4a4a;
    appearance: none;
    background: transparent;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }
  option {
    padding: 10px 30px;
    border: none;
  }
  textarea {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .select-button {
    color: #888;
    line-height: 48px;
    position: absolute;
    top: 0;
    right: 30px;
    z-index: 0;
  }
}
