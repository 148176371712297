footer {
  padding: 50px 0 0;
  color: #b2b2b2;
  background-color: $grey-1;
  a {
    color: #666;
    &.cur-page {
      text-decoration: underline;
    }
  }
  .title {
    font-size: 18px;
    font-weight: $bold;
    line-height: 1.22;
    color: $white;
    margin-bottom: 25px;
  }
  &.footer-style-1 {
    color: #b2b2b2;
    background: #222;

    .title {
      color: #ededed;
    }
    .copyright {
      background: $grey-1;
      margin-top: 30px;
    }
  }
  .social-group {
    .title {
      margin-bottom: 15px;
    }
  }
  &.footer-style-2 {
    background: $accent-color;
    .copyright {
      background: $accent-color;
      margin-top: 25px;
    }
  }
  .social {
    .social-item {
      line-height: 1;
      a {
        color: #b2b2b2;
      }
    }
  }
}
.intro {
  margin-top: 30px;
  .intro-content {
    margin-top: 25px;
    color: #b2b2b2;
  }
}
.extralinks {
  margin-top: 30px;
  width: 100%;
  .title {
    margin-bottom: 15px;
  }
  .extralinks-col {
    width: 50%;
    float: left;
    li {
      list-style: none;
    }
    a{
      line-height: 2.14;
      display: block;
      color: #b1b1b1;
      &:hover {
        color: #999;
      }
    }
  }

}
.fcontact {
  font-size: 18px;
  line-height: 1.22;
  padding-left: 52px;
  color: #666;
  margin-top: 60px;
  height: 100%;
  background-size: auto 100%;
  .fcontact-icon {
    font-size: 20px;
    line-height: 40px;
    color: #052035;
    text-align: center;
    margin-left: -52px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: $white;
    float: left;
  }
  .phonenumber {
    font-weight: $bold;
    color: #a3a3a3;
  }
}
.fcontact-1 {
  margin-top: 30px;
  .title {
    margin-bottom: 22px;
  }
  li {
    font-size: 14px;
    line-height: 1.79;
    padding-right: 50px;
    margin-bottom: 15px;
    .fcontact-icon {
      font-size: 15px;
      line-height: 1.67;
      padding-right: 10px;
    }
  }
}
.copyright {
  margin-top: 30px;
  font-size: 13px;
  text-align: center;
  padding: 16px 0;
  background: #222;
  p {
    margin: 0;
  }
  &-style-1 {
    margin: 50px 0 15px;
  }
}
.other-fcontact {
  margin-top: 30px;
}
.subcribe {
  margin-bottom: 47px;
  .subcribe-form {
    position: relative;
  }
  input {
    padding: 0 55px 0 10px;
    color: $grey-1;
    width: 100%;
    height: 44px;
    background: #dbdbdb;
    border: none;
    box-shadow: none;
  }
  .subcribe-icon {
    font-size: 14px;
    color: $white;
    text-align: center;
    background: $theme-color;
    width: 52px;
    line-height: 44px;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    padding: 0;
    input {
      padding:0;
      position: absolute;
      top: 0;
      right: 0;
      background: transparent;
    }
  }
}

//respondsive for footer
@include respond-to('small-tablet') {
  .extralinks {
    margin-top: 40px;
  }
  .fcontact {
    .fcontact-icon {
      float: none;
      margin-right: 10px;
    }
    .fcontact-info {
      display: inline-block;
    }
  }
}

@include respond-to('medium-mobile') {
  .flogo {
    img {
      transform: scale(0.8);
    }
  }
  .copyright {
    margin-top: 15px;
  }
}
@include respond-to('small-mobile') {
  .fcontact {
    padding: 0;
    .fcontact-icon {
      margin: 0 0 20px 0;
      display: block;
    }
  }
}