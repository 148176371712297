.service-detail-wrapper {
  margin: 30px 0 75px;
}
// Service Sidebar
.services-cate {
  margin-bottom: 70px;
  .services-cate-item {
    font-size: 18px;
    transition: all 0.3s ease-in-out;
    border: none;
    float: none;
    &.active{
      a , a:hover, a:focus{
        color: $theme-color;
        border: none;
        border-left: 5px solid $theme-color;
        background: $white;
      }
    }
    a {
      font-weight: 700;
      line-height: 50px;
      display: block;
      padding: 0 24px;
      border: none;
      border-left: 5px solid #e4e4e4;
      color: $grey-1;
      transition: all 0.3s ease-in-out;
      &:hover, &:focus {
        color: $theme-color;
        border: none;
        border-left: 5px solid $theme-color;
        background: $white;
      }
    }

  }
}

//Service detail
.service-detail {
  margin-bottom: 25px;
}
.service-detail-content {
  .des {
    margin-bottom: 45px;
    p {
      color: $grey-4;
      margin-bottom: 20px;
    }
  }
}

//Responsive
@include respond-to('medium-tablet') {
  .service-sidebar  {
    margin-top: 40px;
    .testimonials-style-3 {
      padding: 30px 18px;
    }
  }
}
@include respond-to('medium-mobile') {
  .service-sidebar {
    .testimonials-style-3  {
      .testimonial {
        padding-left: 40px;
        .tes-content {
          &:before {
            left: -40px;
          }
        }
      }
    }
  }
}
