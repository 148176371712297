.testimonials-wrapper {
  &-pad-1 {
    padding: 75px 0 100px;
  }
  &-dark-bg {
    .tes-content {
      color: $white;
    }
    .author {
      color: #ccc;
    }
  }
}
.carousel-wrapper {
  padding: 120px 200px 107px;
  width: 100%;
  overflow: hidden;
  &:hover {
    .carousel-nav {
      opacity: 1;
      .prev-btn, .next-btn {
        transform: translateX(0);
      }
    }
  }
  .carousel {
    position: relative;

  }
  &-pad-1 {
    padding: 0 200px;
  }
}
.testimonials {
  &-style-2 {
    .testimonial {
      background: none;
      .tes-content {
        font-size: 20px;
        line-height: 1.5;
        color: $white;
        margin-bottom: 30px;
      }
      .author {
        color: #ccc;
      }
    }
  }
  &-style-3 {
    padding: 0;
    .testimonial {
      padding: 10px 0 0 50px;
      text-align: left;
    }
    .tes-content {
      font-size: 18px;
      line-height: 1.67;
      font-weight: $bold;
      color: $grey-1;
      margin-bottom: 20px;
      &:before {
        left: -45px;
      }
    }
  }
  &-pad-1 {
    padding: 45px 220px 30px;
  }
  &-pad-2 {
    padding: 48px 18px 43px;
  }
  &-blue-grey-bg {
    background: $blue-grey;
    .testimonial {
      background: $blue-grey;
    }
  }
}
.carousel-nav {
  opacity: 0;
  transition: all 0.3s ease-in-out;

  .prev-btn, .next-btn {
    font-size: 25px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: rgba($black, 0.5);
    color: $white;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    border-radius: 100%;
    z-index: 10;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    transition-delay: 0.1s;

  }
  .prev-btn {
    left: -20px;
    transform: translateX(-50px);
  }
  .next-btn {
    right: -20px;
    transform: translateX(50px);
  }
}
.testimonial {
  padding: 70px 80px 45px;
  text-align: center;
  background: rgba($white, 0.8);
  &-2 {
    padding: 65px 80px 60px;
  }

}
.tes-content {
  font-size: 25px;
  line-height: 1.4;
  color: $grey-1;
  margin-bottom: 35px;
  position: relative;
  &:before {
    content:'“';
    font-family: Impact;
    font-size: 100px;
    color: $theme-color;
    position: absolute;
    top: -40px;
    left: -40px;
  }
}
.signature {
  padding: 20px 0 15px;
  img {
    display: inline-block !important;
    width: auto !important;
  }
}
.author {
  font-weight: $bold;
  color: #666;
}
//Modify testimonials
.testimonials-nobg {
  .testimonial {
    background: transparent;
  }
}

//Responsive
@include respond-to('medium-tablet') {
  .carousel-wrapper-pad-1 {
    padding: 0;
  }
  .carousel-wrapper {
    padding: 60px 40px;
  }
  .testimonials {
    &-style-3 {
      padding: 0 0 30px;
    }
    &-pad-1 {
      padding-left :50px;
      padding-right: 50px;
    }
  }
  .testimonial {
    padding-left: 50px;
    padding-right: 50px;
  }
  .testimonials-style-2 .testimonial .tes-content:before {
    left: -30px;
  }
  .tes-content:before {
    left: -5px;
  }
  .testimonials-wrapper-dark-bg .tes-content:before {
    left: -35px;
  }
}

@include respond-to('small-tablet') {
  .carousel-wrapper {
    //padding: 0;
  }
}

@include respond-to('medium-mobile') {
  .testimonials-wrapper-pad-1 {
    padding: 0;
  }
  .carousel-wrapper {
    padding: 30px 20px;
  }
  .testimonials {
    &-style-3 {
      padding: 0 0 30px;
      .tes-content {
        font-size: 15px;
        &:before {
          left: -50px;
        }
      }
    }
    &-pad-1 {
      padding-left : 0;
      padding-right: 0;
    }
  }
  .testimonials-style-2 .testimonial .tes-content {
    font-size: 15px;
  }
  .testimonials-wrapper-dark-bg .tes-content:before {
    left: -15px;
  }
  .testimonial {
    padding: 25px 40px;
  }
  .tes-content {
    font-size: 18px;
    margin-bottom: 25px;
    &:before {
      font-size: 70px;
      top: -27px;
      left: -8px;
    }
  }

  .author {
    font-size: 13px;
  }
}
@include respond-to('small-mobile') {
  .testimonials-wrapper-dark-bg .tes-content:before {
    left: -17px;
  }
  .tes-content {
    font-size: 14px;
    &:before {
      font-size: 50px;
      top: -20px;
      left: -20px;
    }
  }
  .author {
    font-size: 11px;
    font-weight: $medium;
  }
}