@charset "UTF-8";
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.mar-1 {
  margin: 20px 0;
}

.mar-2 {
  margin: 35px 0 15px;
}

.marbt-1 {
  margin-bottom: 45px;
}

.marbt-2 {
  margin-bottom: 20px;
}

.marbt-3 {
  margin-bottom: 30px;
}

.marbt-4 {
  margin-bottom: 25px;
}

.pad-1 {
  padding: 45px 0 50px;
}

.pad-top-1 {
  padding-top: 5px;
}

.pad-right-1 {
  padding-right: 5px;
}

.pad-bottom-1 {
  padding-bottom: 5px;
}

.pad-left-1 {
  padding-left: 5px;
}

.row-flex, .row-flex > div[class*='col-'] {
  display: flex;
  flex-wrap: wrap;
}

.row-flex > div.col-xs-12 {
  display: block;
}

.fitcol {
  padding: 0;
}

.fitleft {
  padding-left: 0;
}

.fitright {
  padding-right: 0;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

table.autable > tbody > tr > td, table.autablee > tbody > tr > th, table.autable > tfoot > tr > td,
table.autable > tfoot > tr > th, table.autable > thead > tr > td, table.autable > thead > tr > th {
  padding: 0 15px;
  vertical-align: middle;
}

table.autable > tbody > tr > td.fitleft, table.autablee > tbody > tr > th.fitleft, table.autable > tfoot > tr > td.fitleft,
table.autable > tfoot > tr > th.fitleft, table.autable > thead > tr > td.fitleft, table.autable > thead > tr > th.fitleft {
  padding-left: 0;
}

table.autable > tbody > tr > td.fitright, table.autablee > tbody > tr > th.fitright, table.autable > tfoot > tr > td.fitright,
table.autable > tfoot > tr > th.fitright, table.autable > thead > tr > td.fitright, table.autable > thead > tr > th.fitright {
  padding-right: 0;
}

.uppercase {
  text-transform: uppercase;
}

.no-decoration {
  text-decoration: none;
}

.grey-bg {
  background: #333;
}

.grey-bg-1 {
  background: #f2f2f2;
}

.blue-grey-bg {
  background: #f6f6f6;
}

.white-bg {
  background: #fff;
}

.darkblue-bg {
  background: rgba(19, 37, 53, 0.95);
}

.accent-bg {
  background: #052035;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a, a:hover, a:active, a:focus {
  text-decoration: none;
}

.row, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-sm-4, .col-sm-6, .col-sm-12, .col-xs-12 {
  line-height: 0;
}

.row.fit-left, .col-md-3.fit-left, .col-md-4.fit-left, .col-md-5.fit-left, .col-md-6.fit-left, .col-sm-4.fit-left, .col-sm-6.fit-left, .col-sm-12.fit-left, .col-xs-12.fit-left {
  padding-left: 0;
}

.row.fit-right, .col-md-3.fit-right, .col-md-4.fit-right, .col-md-5.fit-right, .col-md-6.fit-right, .col-sm-4.fit-right, .col-sm-6.fit-right, .col-sm-12.fit-right, .col-xs-12.fit-right {
  padding-right: 0;
}

@media (max-width: 768px) {
  .row.fit-left, .col-md-3.fit-left, .col-md-4.fit-left, .col-md-5.fit-left, .col-md-6.fit-left, .col-sm-4.fit-left, .col-sm-6.fit-left, .col-sm-12.fit-left, .col-xs-12.fit-left {
    padding-left: 15px;
  }
  .row.fit-right, .col-md-3.fit-right, .col-md-4.fit-right, .col-md-5.fit-right, .col-md-6.fit-right, .col-sm-4.fit-right, .col-sm-6.fit-right, .col-sm-12.fit-right, .col-xs-12.fit-right {
    padding-right: 15px;
  }
}

.menuoffset {
  max-width: 1200px;
}

.container-fluid.offset {
  padding: 0 60px;
}

.banner {
  padding: 75px 0;
  background: rgba(1, 170, 193, 0.8);
}

.banner .banner-content {
  font-size: 25px;
  line-height: 1.44;
  color: #fff;
  text-align: center;
}

.pagetitle {
  height: 121px;
  background-size: cover;
  display: table;
  float: left;
}

.pagetitle .title {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  display: table-cell;
  vertical-align: middle;
}

.section-title-style-1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
}

.section-title-underline {
  display: inline-block;
  border-bottom: 2px solid #fff;
}

.section-title-underline-1:after {
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  content: '';
  height: 3px;
  width: 80px;
  background: #333;
  text-align: center;
}

.section-title-style-2 {
  font-size: 60px;
  font-weight: 700;
  text-decoration: none;
}

.section-title-style-3 {
  font-size: 24px;
}

.section-title-style-4 {
  font-size: 18px;
}

.section-title-color-grey {
  color: #333;
}

.section-title-color-white {
  color: #fff;
}

.section-title-center {
  text-align: center;
}

.section-title-moredes {
  color: #ccc;
}

.section-title-uppercase {
  text-transform: uppercase;
}

.section-title-no-decoration {
  text-decoration: none;
}

.section-title.darker-color {
  color: #052035;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 9999;
}

#status {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -64px;
  margin-left: -64px;
  width: 128px;
  height: 128px;
  z-index: 9999;
  background: red;
}

.grid-width {
  width: 24.7%;
}

.grid-width-1 {
  width: 48.7%;
}

.grid-width-2 {
  width: 50.42%;
}

@media (max-width: 1367px) {
  .menuoffset {
    max-width: 970px;
  }
}

@media (max-width: 991px) {
  .menuoffset {
    max-width: 750px;
    padding: 0 15px;
    margin: 0 auto;
    display: block;
  }
  .grid-width {
    width: 49.5%;
  }
  .grid-width-1 {
    width: 100%;
  }
  .grid-width-2 {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .container-fluid.offset {
    padding: 0 15px;
  }
}

@media (max-width: 479px) {
  .section-title-style-1 {
    font-size: 27px;
  }
  .section-title-style-3 {
    font-size: 18px;
  }
  .section-title-style-4 {
    font-size: 18px;
  }
  .banner {
    padding: 50px 0;
  }
  .banner .banner-content {
    font-size: 18px;
  }
  .pagetitle.pagetitle-style-1 {
    float: none;
    height: auto;
    display: block;
    margin: 20px 0;
    text-align: center;
  }
  .pagetitle.pagetitle-style-1 .title {
    font-size: 20px;
    display: block;
  }
}

@media (max-width: 320px) {
  .grid-width {
    width: 100%;
  }
}

.breadcrumb {
  padding: 25px 0;
  background: none;
}

.breadcrumb-1 {
  padding-bottom: 20px;
}

.breadcrumb p {
  font-size: 13px;
  line-height: 1.69;
}

.breadcrumb p a {
  color: #333;
}

.breadcrumb p a:before {
  content: '/';
  padding: 0 10px;
}

.breadcrumb p a:first-child:before {
  content: '\f015';
  font-family: FontAwesome;
  padding: 0 5px 0 0;
}

.breadcrumb p a:last-child {
  color: #666;
}

.aubtn {
  display: inline-block;
  background: transparent;
  transition: all 0.3s ease-in-out;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.aubtn-primary {
  line-height: 50px;
  font-weight: 700;
  text-transform: capitalize;
  color: #fff;
  position: relative;
  z-index: 2;
}

.aubtn-primary:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: #01aac1;
}

.aubtn-primary:after {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 0;
  height: 100%;
  top: 50%;
  left: 50%;
  background: #fff;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.aubtn-primary:hover {
  color: #01aac1;
}

.aubtn-primary:hover:after {
  width: 100%;
  opacity: 1;
}

.aubtn-primary-invert {
  color: #052035;
}

.aubtn-primary-invert:before {
  background: #e7e7e7;
}

.aubtn-primary-invert:after {
  background: #01aac1;
}

.aubtn-primary-invert:hover {
  color: #fff;
}

.aubtn-primary-invert-style-2:before {
  background: #fff;
}

.aubtn-primary-style-1:hover {
  color: #052035;
}

.aubtn-primary-style-1:hover:after {
  background: #e7e7e7;
}

.aubtn-primary-style-2:hover {
  color: #052035;
}

.aubtn-fix {
  line-height: 48px;
  font-weight: 700;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #999;
  margin-right: 5px;
  padding: 0;
}

.aubtn-fix.active {
  border: 1px solid #01aac1;
  color: #01aac1;
}

.aubtn-fix-1 {
  width: 120px;
  text-align: center;
  padding: 0;
}

.aubtn-fix-2 {
  width: 133px;
  text-align: center;
  padding: 0;
}

.aubtn-fix-3 {
  width: 150px;
  text-align: center;
  padding: 0;
}

.aubtn.submit-btn {
  font-weight: 700;
  border-color: #01aac1;
}

.aubtn.submit-btn input {
  background: none;
  box-shadow: none;
  border: none;
  display: block;
  height: 100%;
  margin-bottom: 0;
}

.aubtn-style-1 {
  border: 1px solid;
  line-height: 48px;
}

.aubtn-style-2 {
  border: 2px solid;
  line-height: 46px;
}

.aubtn-size-1 {
  padding: 0 17px;
}

.aubtn-size-2 {
  padding: 0 22px;
}

.aubtn-size-3 {
  padding: 0 25px;
}

.aubtn-size-4 {
  padding: 0 30px;
}

.aubtn-size-5 {
  padding: 0 35px;
}

.aubtn-size-6 {
  padding: 0 38px;
}

.aubtn-size-7 {
  padding: 0 42px;
}

.aubtn-size-8 {
  padding: 0 50px;
}

.aubtn-color-1 {
  background: #ededed;
  color: #b4b4b4;
}

.aubtn-color-2 {
  background: #ededed;
  color: #666;
}

.aubtn-color-2:hover {
  color: #666;
}

.aubtn-color-3 {
  background: transparent;
  color: #999;
}

.aubtn-color-4 {
  background: transparent;
  color: #333;
}

.aubtn-color-5 {
  background: #e7e7e7;
  color: #052035;
}

.aubtn-color-6 {
  background: transparent;
  color: #fff;
  border-color: #fff;
}

.aubtn-color-6:hover {
  border-color: #01aac1;
}

.aubtn-color-7 {
  background: #01aac1;
  color: #fff;
}

.aubtn-color-7:hover {
  background: #fff;
  color: #01aac1;
}

.aubtn-color-8 {
  background: #fff;
  color: #999;
}

.auprevbtn, .aunextbtn {
  font-size: 14px;
  line-height: 1.57;
  font-weight: 700;
  color: #888;
  transition: all 0.3s ease-in-out;
}

.auprevbtn:hover, .aunextbtn:hover {
  color: #01aac1;
}

.aureply {
  font-size: 13px;
  font-weight: 700;
  color: #01aac1;
  cursor: pointer;
}

.rm {
  line-height: 1.57;
  font-weight: 700;
  text-transform: capitalize;
  color: #01aac1;
}

.rm:before {
  content: '\f0da';
  font-family: FontAwesome;
  padding-right: 8px;
}

.rm:hover {
  color: #01aac1;
}

.quantity-btn {
  line-height: 50px;
  padding: 0 47px 0 17px;
  background: #ededed;
  display: inline-block;
  position: relative;
  color: #333;
  margin-right: 20px;
}

.quantity-btn .add, .quantity-btn .minus {
  font-size: 13px;
  line-height: 1;
  position: absolute;
  right: 14px;
  color: #999;
  cursor: pointer;
}

.quantity-btn .add {
  top: 0;
  padding-top: 14px;
}

.quantity-btn .minus {
  bottom: 0;
  padding-bottom: 14px;
}

@media (max-width: 479px) {
  .aubtn-fix-3 {
    width: 120px;
    padding: 0;
  }
}

.auform.cmt {
  height: 170px;
}

.auform input, .auform select, .auform textarea {
  line-height: 48px;
  padding: 0 30px;
  width: 100%;
  height: 50px;
  color: #888;
  border: 1px solid #2c2c2c;
  display: inline-block;
}

.auform input.from-width-1, .auform select.from-width-1, .auform textarea.from-width-1 {
  width: 215px;
}

.auform input, .auform .select-group, .auform textarea {
  background: #fff;
  margin-bottom: 20px;
}

.auform .select-group {
  width: 100%;
  position: relative;
  background: #fff;
}

.auform select {
  color: #4a4a4a;
  appearance: none;
  background: transparent;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.auform option {
  padding: 10px 30px;
  border: none;
}

.auform textarea {
  padding-top: 15px;
  padding-bottom: 15px;
}

.auform .select-button {
  color: #888;
  line-height: 48px;
  position: absolute;
  top: 0;
  right: 30px;
  z-index: 0;
}

.icon-boxes-pad-1 {
  padding-top: 40px;
}

.icon-box {
  margin-bottom: 45px;
}

.icon-box .icon-box-content {
  margin-top: 15px;
}

.icon-box .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.22;
  color: #333;
  margin-bottom: 10px;
}

.pagination {
  margin-top: 30px;
}

.pagination .aubtn:hover {
  color: #01aac1;
}

.socials li {
  line-height: 50px;
  display: inline-block;
}

.socials li a {
  color: #d3d3d3;
  padding: 8px;
}

.socials .social-item-1 {
  line-height: 100px;
}

.socials .menu-button-wrapper {
  line-height: 100px;
}

.socials .menu-button-wrapper .menu-button {
  float: none;
  font-size: 14px;
  color: #d3d3d3;
  margin: 0;
  padding: 8px;
}

.small-mainheader .socials .social-item-1 {
  line-height: 70px;
}

.small-mainheader .socials .social-item-1 a {
  color: #555;
}

.small-mainheader .socials .menu-button-wrapper .menu-button {
  color: #555;
}

.stuck .socials .social-item-1 {
  line-height: 80px;
}

.stuck .small-mainheader .socials .social-item-1 {
  line-height: 70px;
}

@media (max-width: 1224px) {
  .socials .social-item-1 {
    line-height: 80px;
  }
}

@media (max-width: 479px) {
  .socials li a {
    padding: 4px;
  }
}

.about {
  margin: 10px 0 130px;
}

.about-img {
  line-height: 0;
  text-align: center;
}

.about-img img {
  max-width: 100%;
}

.about-content .section-title {
  margin-bottom: 35px;
}

.about-content .des p {
  color: #666;
  margin-bottom: 20px;
}

.members-wrapper {
  padding: 50px 0 70px;
  display: inline-block;
}

.members-wrapper .section-title-underline {
  border-bottom-color: #333;
  line-height: 1;
}

.member {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  display: inline-block;
}

.member-info-wrapper {
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: transparent;
  transition: all 0.4s ease-in-out;
}

.member-info-hoverbg {
  width: 100%;
  background: rgba(5, 32, 53, 0.95);
}

.member-info {
  padding: 25px;
}

.member-info .name {
  font-size: 18px;
  line-height: 1.44;
  font-weight: 700;
  color: #fff;
  margin-bottom: 5px;
}

.member-info .job {
  color: #01aac1;
  margin-bottom: 0;
}

@media (max-width: 479px) {
  .about {
    margin-bottom: 50px;
  }
}

.blog-list-wrapper {
  margin: 30px 0 80px;
}

.blogs {
  padding: 60px 0 45px;
}

.blogs-1 {
  padding: 65px 0 65px;
}

.blog {
  margin-bottom: 55px;
}

.blog .blog-content .section-title {
  margin: 25px 0 0;
}

.blog .blog-content .section-title a {
  color: #333;
}

.blog .blog-content .meta {
  line-height: 45px;
}

.blog .blog-content .des {
  padding-right: 75px;
  margin-bottom: 20px;
}

.blog-1 {
  margin-bottom: 30px;
}

.blog-1 .blog-img {
  padding: 0;
}

.blog-1 .blog-info .name {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.375;
  margin: 15px 0 10px;
}

.blog-1 .blog-info .name a {
  color: #333;
}

.blog-1 .blog-info .meta {
  line-height: 1.69;
  color: #666;
  font-weight: 400;
}

.blog-1 .blog-info .meta:before {
  content: '\f017';
  font-family: FontAwesome;
  font-size: 13px;
  color: #052035;
  margin-right: 7px;
}

.blog-img {
  line-height: 0;
  display: block;
  padding-right: 15px;
}

.blog-img img {
  max-width: 100%;
}

.blog .section-title, .blog-detail-content .section-title {
  margin-bottom: 0;
}

.blog .section-title a, .blog-detail-content .section-title a {
  color: #333;
}

.blog .meta, .blog-detail-content .meta {
  font-size: 13px;
  line-height: 55px;
  font-weight: 700;
  color: #999;
}

.blog .meta .time:before, .blog-detail-content .meta .time:before {
  content: '\f017';
  font-family: FontAwesome;
  padding-right: 10px;
}

.blog .meta .time:after, .blog-detail-content .meta .time:after {
  content: '|';
  padding: 0 27px 0 12px;
}

.blog .meta .author, .blog-detail-content .meta .author {
  color: #999;
}

.blog .meta .author:before, .blog-detail-content .meta .author:before {
  content: '\f007';
  font-family: FontAwesome;
  padding-right: 10px;
}

.blog .des, .blog-detail-content .des {
  padding-right: 35px;
  margin: 10px 0 30px;
}

.blog .des p, .blog-detail-content .des p {
  color: #666;
  margin-bottom: 15px;
}

.sub-blog {
  margin-bottom: 30px;
}

.sub-blog .title {
  margin: 0 0 12px 0;
}

.sub-blog .title a {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.44;
  color: #333;
}

.sub-blog .des {
  line-height: 1.57;
  color: #666;
  margin-bottom: 20px;
}

.moremedia {
  padding: 0 0 30px;
  border-bottom: 1px solid #e5e5e5;
}

.moremedia .tag, .moremedia .social {
  display: inline-block;
  color: #052035;
  font-size: 14px;
  line-height: 1.57;
}

.moremedia .tag {
  float: left;
}

.moremedia .tag a {
  color: #888;
  font-size: 13px;
  line-height: 1.69;
}

.moremedia .social {
  float: right;
}

.moremedia .social .social-item {
  display: inline-block;
  line-height: 1;
  padding: 8px;
}

.moremedia .social .social-item:first-child {
  padding-left: 16px;
}

.moremedia .social .social-item a {
  color: #333;
}

.related-blogs {
  padding: 35px 0 40px;
}

.related-blogs .next-blog {
  text-align: right;
}

.related-blogs .auprevbtn, .related-blogs .aunextbtn {
  display: inline-block;
  padding-bottom: 5px;
  font-weight: 400;
}

.related-blogs .auprevbtn:before {
  content: "\f060";
  font-family: FontAwesome;
  padding-right: 10px;
}

.related-blogs .aunextbtn:after {
  content: "\f061";
  font-family: FontAwesome;
  padding-left: 10px;
}

.related-blogs .blog-name {
  line-height: 1.57;
}

.related-blogs .blog-name a {
  color: #333;
  font-size: 14px;
}

.blog-detail-wrapper {
  margin: 30px 0 85px;
}

.blog-sidebar .blog-sidebar-banner {
  padding: 15px;
  display: table;
  text-align: center;
  background-color: transparent;
  height: 246px;
  position: relative;
}

.blog-sidebar .blog-sidebar-banner .title {
  font-size: 18px;
  line-height: 1.33;
  color: #01aac1;
  display: table-cell;
  vertical-align: middle;
  margin-bottom: 0;
}

.blog-sidebar .blog-sidebar-banner .overlay {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.blog-cate {
  margin-bottom: 50px;
}

.blog-cate .section-title-underline {
  border-bottom-color: #333;
  border-bottom-width: 1px;
  line-height: 1.2;
}

.blog-cate .blog-cate-item {
  font-size: 16px;
  line-height: 2.5;
  font-weight: 700;
}

.blog-cate .blog-cate-item a {
  display: block;
  color: #333;
}

.blog-cate .blog-cate-item:hover a {
  color: #01aac1;
}

.blog-detail {
  margin-bottom: 65px;
}

.blog-detail .other-author {
  background: #e5e5e5;
  line-height: 55px;
  font-weight: 500;
}

.blog-detail .other-author .avt {
  display: inline-block;
  line-height: 0;
}

.blog-detail .other-author .author-info {
  line-height: 1.57;
  color: #666;
  padding-left: 20px;
  display: inline-block;
  margin-bottom: 0;
}

.blog-detail .other-author .author-info .name, .blog-detail .other-author .author-info .job {
  color: #333;
}

.blog-detail .other-author .author-info .name:after {
  content: "/";
  padding: 0 13px 0 10px;
}

.reply .section-title {
  margin-bottom: 13px;
  color: #333b47;
}

.reply .formnote {
  line-height: 1.7;
  color: #666;
  margin-bottom: 35px;
}

.reply .auform input, .reply .auform textarea {
  color: #666;
  border: 1px solid #dedede;
  border-radius: 3px;
  background: #f2f2f2;
}

.reply .auform textarea {
  width: 100%;
  height: 171px;
  line-height: 1.7;
  text-align: justify;
}

.reply .submit-btn {
  margin: 0;
}

.cmts {
  padding: 35px 0 45px;
}

.cmts .section-title {
  color: #333b47;
}

.cmts .cmt {
  padding-left: 70px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ececec;
}

.cmts .cmt .cmt-avt {
  margin-left: -70px;
  float: left;
}

.cmts .cmt .cmt-content {
  line-height: 1.57;
  color: #666;
  margin-bottom: 15px;
}

.cmts .cmt .cmt-reply {
  border: none;
}

.cmts .cmt-reply {
  margin-top: 30px;
}

.cmts .author {
  font-size: 13px;
  color: #999;
  font-weight: 700;
  margin-right: 28px;
  line-height: 1.84;
}

@media (max-width: 991px) {
  .blog-sidebar {
    padding-top: 50px;
  }
}

body {
  font: 14px "Noto Sans", sans-serif;
  font-weight: 500;
  color: #555;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  line-height: 1.57;
}

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix:before, .clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

.clients {
  background: rgba(237, 237, 237, 0.7);
  margin-bottom: 10px;
}

.logo-group {
  padding: 84px 0 75px;
}

.logo-item {
  width: 20%;
  display: inline-block;
  list-style: none;
  float: left;
  text-align: center;
}

.logo-item img {
  max-width: 100%;
}

.contact {
  margin-bottom: 60px;
}

.contact .section-title {
  font-weight: 700;
}

.contact-info-title {
  color: #666;
  margin-bottom: 40px;
}

.contact-info {
  border: 1px solid #e5e5e5;
  padding: 20px 20px 0 40px;
  margin-bottom: 70px;
}

.contact-info-item {
  padding: 20px 15px 20px 45px;
}

.contact-info-item .contact-info-item-icon {
  font-size: 33px;
  color: #01aac1;
  margin-left: -45px;
  float: left;
}

.contact-info-item .contact-info-item-content {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 700;
  color: #333;
  margin-top: 5px;
}

.feedback-form-wrapper {
  padding-right: 35px;
  margin-bottom: 40px;
}

.feedback-form-wrapper .section-title {
  font-weight: 700;
  margin-bottom: 25px;
}

.feedback-form-wrapper input, .feedback-form-wrapper textarea {
  background: #ededed;
  border: 1px solid #dedede;
  border-radius: 3px;
  color: #333;
}

.feedback-form-wrapper ::-webkit-input-placeholder {
  color: #666;
}

.feedback-form-wrapper :-moz-placeholder {
  color: #666;
  opacity: 1;
}

.feedback-form-wrapper ::-moz-placeholder {
  color: #666;
  opacity: 1;
}

.feedback-form-wrapper :-ms-input-placeholder {
  color: #666;
}

.feedback-form-wrapper .message {
  height: 201px;
}

.feedback-form-wrapper .aubtn {
  margin: 12px 0 0 0;
}

.feedback-form-wrapper .aubtn input {
  padding: 0 50px;
  color: #fff;
}

.feedback-form-wrapper .aubtn:hover input {
  color: #01aac1;
}

#map {
  height: 411px;
}

@media (max-width: 991px) {
  .feedback-form-wrapper {
    padding-right: 0;
  }
  .contact-map {
    padding: 20px 0;
  }
}

@media (max-width: 479px) {
  .contact-info {
    padding: 20px 15px 30px;
  }
  .contact-info .contact-info-item {
    padding-right: 0;
  }
  .contact-info .contact-info-item .contact-info-item-content {
    font-weight: 500;
  }
}

.cta {
  padding: 67px 0 70px;
  background: #052035;
  position: relative;
}

.cta-pad-1 {
  padding: 35px 0;
  background: #01aac1;
}

.cta-lightgrey-bg {
  background: #e5e5e5;
}

.cta-lightgrey-bg .section-title {
  color: #333;
}

.cta-lightgrey-bg .section-title-moredes {
  color: #666;
}

.cta-lightgrey-bg .auform input, .cta-lightgrey-bg .auform select {
  line-height: 50px;
  border: none;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
}

.cta-lightgrey-bg .aubtn {
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
}

.cta .aubtn input {
  padding: 0 50px;
  background: none;
  margin-bottom: 0;
  color: #fff;
  border: none;
}

.cta .aubtn input:hover {
  color: #01aac1;
}

.cta-grey-bg {
  background: #333;
}

.cta-title {
  font-size: 20px;
  color: #fff;
  margin: 32px 0 0;
  font-weight: 700;
}

.getaquotebtn {
  margin: 20px 0 20px 35px;
}

.getaquotebtn:hover {
  border-color: #fff;
}

@media (max-width: 991px) {
  .getaquotebtn {
    margin-left: 0;
  }
  .getaquotebtn-wrapper, .cta-title {
    text-align: center;
  }
}

footer {
  padding: 50px 0 0;
  color: #b2b2b2;
  background-color: #333;
}

footer a {
  color: #666;
}

footer a.cur-page {
  text-decoration: underline;
}

footer .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.22;
  color: #fff;
  margin-bottom: 25px;
}

footer.footer-style-1 {
  color: #b2b2b2;
  background: #222;
}

footer.footer-style-1 .title {
  color: #ededed;
}

footer.footer-style-1 .copyright {
  background: #333;
  margin-top: 30px;
}

footer .social-group .title {
  margin-bottom: 15px;
}

footer.footer-style-2 {
  background: #052035;
}

footer.footer-style-2 .copyright {
  background: #052035;
  margin-top: 25px;
}

footer .social .social-item {
  line-height: 1;
}

footer .social .social-item a {
  color: #b2b2b2;
}

.intro {
  margin-top: 30px;
}

.intro .intro-content {
  margin-top: 25px;
  color: #b2b2b2;
}

.extralinks {
  margin-top: 30px;
  width: 100%;
}

.extralinks .title {
  margin-bottom: 15px;
}

.extralinks .extralinks-col {
  width: 50%;
  float: left;
}

.extralinks .extralinks-col li {
  list-style: none;
}

.extralinks .extralinks-col a {
  line-height: 2.14;
  display: block;
  color: #b1b1b1;
}

.extralinks .extralinks-col a:hover {
  color: #999;
}

.fcontact {
  font-size: 18px;
  line-height: 1.22;
  padding-left: 52px;
  color: #666;
  margin-top: 60px;
  height: 100%;
  background-size: auto 100%;
}

.fcontact .fcontact-icon {
  font-size: 20px;
  line-height: 40px;
  color: #052035;
  text-align: center;
  margin-left: -52px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #fff;
  float: left;
}

.fcontact .phonenumber {
  font-weight: 700;
  color: #a3a3a3;
}

.fcontact-1 {
  margin-top: 30px;
}

.fcontact-1 .title {
  margin-bottom: 22px;
}

.fcontact-1 li {
  font-size: 14px;
  line-height: 1.79;
  padding-right: 50px;
  margin-bottom: 15px;
}

.fcontact-1 li .fcontact-icon {
  font-size: 15px;
  line-height: 1.67;
  padding-right: 10px;
}

.copyright {
  margin-top: 30px;
  font-size: 13px;
  text-align: center;
  padding: 16px 0;
  background: #222;
}

.copyright p {
  margin: 0;
}

.copyright-style-1 {
  margin: 50px 0 15px;
}

.other-fcontact {
  margin-top: 30px;
}

.subcribe {
  margin-bottom: 47px;
}

.subcribe .subcribe-form {
  position: relative;
}

.subcribe input {
  padding: 0 55px 0 10px;
  color: #333;
  width: 100%;
  height: 44px;
  background: #dbdbdb;
  border: none;
  box-shadow: none;
}

.subcribe .subcribe-icon {
  font-size: 14px;
  color: #fff;
  text-align: center;
  background: #01aac1;
  width: 52px;
  line-height: 44px;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: 0;
}

.subcribe .subcribe-icon input {
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
}

@media (max-width: 768px) {
  .extralinks {
    margin-top: 40px;
  }
  .fcontact .fcontact-icon {
    float: none;
    margin-right: 10px;
  }
  .fcontact .fcontact-info {
    display: inline-block;
  }
}

@media (max-width: 479px) {
  .flogo img {
    transform: scale(0.8);
  }
  .copyright {
    margin-top: 15px;
  }
}

@media (max-width: 320px) {
  .fcontact {
    padding: 0;
  }
  .fcontact .fcontact-icon {
    margin: 0 0 20px 0;
    display: block;
  }
}

/*-------------------------------------------------------------------
[Table of contents]
1. Top bar / . topbar
2. Logo / .logo
3. Main header / .mainheader
4. Menu button  / .menu-button, .closebutton
5. Main menu  / .mainmenu
6 Menu level / .menulevel
7. Main menu item / .mainmenu-item
8. Sticky menu / stuck
-------------------------------------------------------------------*/
.header-float {
  position: absolute;
  z-index: 10;
  width: 100%;
}

.header-float .menu-button {
  color: #fff;
}

.header-float .mainmenu-item > .menulevel {
  left: 0;
}

.header-float .mainmenu-item > .menulevel .menulevel {
  left: -100%;
}

.header-float .mainmenu-item > a {
  color: #b1b1b1;
}

.header-float .mainmenu-item:hover > a {
  color: #fff;
}

.header-float #logo-1 {
  display: none;
}

.header-float .menulevel {
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.3);
}

.sticked-menu-wrapper {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 50px;
}

.sticked-menu-wrapper .menu-button {
  color: #fff;
}

.topbar {
  color: #d3d3d3;
  height: 50px;
  background: #052035;
}

.topbar .general-info {
  float: left;
}

.topbar .general-info .info-box {
  display: inline-block;
  line-height: 50px;
  padding-right: 49px;
}

.topbar .general-info .info-box .info-icon {
  font-size: 15px;
}

.topbar .general-info .info-box .info-content {
  font-size: 13px;
}

.topbar-canvas {
  height: 100px;
}

.topbar-invert {
  background: #fff;
  color: #052035;
}

.topbar-invert .social .social-item a {
  color: #052035;
}

.topbar-mobile {
  display: none;
}

.logo {
  line-height: 100%;
  max-width: 100%;
  height: 100px;
  display: table;
  float: left;
}

.logo a {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

.mainheader {
  height: 100px;
}

.mainheader.small-mainheader {
  height: 70px;
}

.mainheader.small-mainheader .logo {
  height: 70px;
}

.mainheader.small-mainheader .mainmenu .mainmenu-item > a {
  line-height: 70px;
}

.mainheader.small-mainheader .mainmenu.default-menu {
  height: 70px;
}

.mainheader.small-mainheader .mainmenu .mainmenu-item > .menulevel {
  top: 70px;
}

.mainheader-centered {
  text-align: center;
}

.mainheader-centered .menu-wrapper {
  display: inline-block;
}

.menu-button, .close-button {
  cursor: pointer;
  display: none;
}

.menu-button {
  font-size: 30px;
  margin-top: 30px;
  float: right;
  color: #052035;
}

.close-button {
  font-size: 20px;
  color: #052035;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 20px;
  z-index: 101;
  transition-delay: 0.5s;
  transition: all 0.3s ease-in-out;
}

.close-button:hover {
  color: #01aac1;
}

.accent-bg .menu-button {
  margin-right: 22px;
}

.menu-wrapper {
  text-align: left;
}

.menu-wrapper-float {
  float: right;
}

.mainmenu li {
  position: relative;
}

.mainmenu li a {
  line-height: 100px;
  color: #333;
  cursor: pointer;
}

.mainmenu li a:hover {
  color: #01aac1;
}

.mainmenu.default-menu {
  height: 100px;
}

.mainmenu li:hover > .menulevel {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu-color-grey .mainmenu li a {
  color: #555;
}

.stuck .menulevel, .menulevel {
  width: 220px;
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  transition: all 0.25s ease-in-out;
  transform: translateY(20px);
}

.mainmenu-item {
  color: #333;
  padding: 0 22px;
  display: inline-block;
}

.mainmenu-item a {
  font-size: 15px;
  font-weight: 700;
}

.mainmenu-item:first-child {
  padding-left: 0;
}

.mainmenu-item:last-child {
  padding-right: 0;
}

.mainmenu-item:hover {
  color: #01aac1;
}

.mainmenu-item > .menulevel {
  top: 100px;
  left: 0;
  margin-left: -10px;
}

.mainmenu-item > .menulevel li a, .mainmenu-item > .menulevel li h3 {
  padding: 0 28px 0 34px;
  display: block;
  font-size: 14px;
  line-height: 52px;
}

.mainmenu-item > .menulevel li a:hover, .mainmenu-item > .menulevel li h3:hover {
  color: #fff;
  background-color: #01aac1;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
}

.mainmenu-item > .menulevel li.haschild > a:after, .mainmenu-item > .menulevel li.haschild > h3:after {
  content: "\f105";
  font-family: FontAwesome;
  font-size: 14px;
  line-height: 52px;
  color: #333;
  position: absolute;
  top: 0;
  right: 20px;
  padding: 0 10px;
}

.mainmenu-item > .menulevel li.haschild > a:hover:after, .mainmenu-item > .menulevel li.haschild > h3:hover:after {
  color: #fff;
}

.mainmenu-item > .menulevel .menulevel {
  top: 0;
  right: -100%;
  padding-bottom: 18px;
  overflow: hidden;
}

.mainheader-wrapper {
  position: relative;
}

#resmenufloat {
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 100;
}

.stuck {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  background: #fff;
}

.stuck-nonebg {
  background: none;
}

.stuck .logo {
  height: 80px;
}

.stuck .logo img {
  width: 60%;
}

.stuck .menu-button {
  font-size: 20px;
}

.stuck .mainheader {
  background: #fff;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
}

.stuck .mainheader, .stuck .mainmenu {
  height: 80px;
}

.stuck .mainheader > li > a, .stuck .mainheader > li > h3, .stuck .mainmenu > li > a, .stuck .mainmenu > li > h3 {
  color: #333;
  line-height: 80px;
}

.stuck .mainheader > li:hover > a, .stuck .mainheader > li:hover > h3, .stuck .mainmenu > li:hover > a, .stuck .mainmenu > li:hover > h3 {
  color: #01aac1;
}

.stuck .mainheader.small-mainheader, .stuck .mainmenu.small-mainheader {
  height: 70px;
}

.stuck .mainmenu-item {
  color: #333;
}

.stuck .mainmenu-item:hover {
  color: #01aac1;
}

.stuck .mainmenu-item > .menulevel {
  top: 80px;
}

.stuck .mainmenu-item > .menulevel li > a, .stuck .mainmenu-item > .menulevel li > h3 {
  color: #333;
}

.stuck .mainmenu-item > .menulevel li:hover {
  background-color: #01aac1;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
}

.stuck .mainmenu-item > .menulevel li:hover > a, .stuck .mainmenu-item > .menulevel li:hover > h3 {
  color: #fff;
}

@media (max-width: 1367px) {
  .mainmenu-item > .menulevel .menulevel {
    left: -100%;
  }
  .mainmenu-2 .menuoffset {
    max-width: 930px;
  }
}

@media (max-width: 1224px) {
  .mainheader.small-mainheader .menu-wrapper {
    top: 70px;
  }
  .mainheader.small-mainheader .mainmenu.default-menu {
    height: auto;
    top: 70px;
  }
  .header-float .menu-wrapper, .menu-wrapper {
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: absolute;
    left: 0;
    top: 80px;
    z-index: 990;
  }
  .header-float .menu-wrapper-float, .menu-wrapper-float {
    float: none;
  }
  .header-float .menu-wrapper .default-menu.mainmenu, .menu-wrapper .default-menu.mainmenu {
    display: none;
    z-index: 100;
    background: #fff;
    overflow: hidden;
    height: auto;
    width: 100%;
    transition: none;
  }
  .header-float .menu-wrapper .default-menu.mainmenu > li:first-child, .menu-wrapper .default-menu.mainmenu > li:first-child {
    border: none;
  }
  .header-float .menu-wrapper .default-menu.mainmenu li, .menu-wrapper .default-menu.mainmenu li {
    position: relative;
    overflow: hidden;
    border-top: 1px solid #eaeaea;
  }
  .header-float .menu-wrapper .default-menu.mainmenu li a, .menu-wrapper .default-menu.mainmenu li a {
    display: block;
  }
  .header-float .menu-wrapper .default-menu.mainmenu li a:hover, .menu-wrapper .default-menu.mainmenu li a:hover {
    background-color: #01aac1;
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
    color: #fff;
  }
  .header-float .menu-wrapper .default-menu.mainmenu li.haschild > a span, .menu-wrapper .default-menu.mainmenu li.haschild > a span {
    position: relative;
  }
  .header-float .menu-wrapper .default-menu.mainmenu li.haschild > a span:after, .menu-wrapper .default-menu.mainmenu li.haschild > a span:after {
    content: "\f107";
    font-family: FontAwesome;
    font-size: 14px;
    line-height: 60px;
    color: #333;
    position: absolute;
    top: 0;
    right: 20px;
    padding: 0 0 0 10px;
    background: none;
  }
  .header-float .menu-wrapper .default-menu.mainmenu li.haschild > a:hover:after, .menu-wrapper .default-menu.mainmenu li.haschild > a:hover:after {
    color: #fff;
  }
  .header-float .menu-wrapper .default-menu.mainmenu li.haschild > a.ropen, .header-float .menu-wrapper .default-menu.mainmenu li.haschild > h3.ropen, .menu-wrapper .default-menu.mainmenu li.haschild > a.ropen, .menu-wrapper .default-menu.mainmenu li.haschild > h3.ropen {
    background: #01aac1;
    color: #fff;
  }
  .header-float .menu-wrapper .default-menu.mainmenu li.haschild > a.ropen span:after, .header-float .menu-wrapper .default-menu.mainmenu li.haschild > h3.ropen span:after, .menu-wrapper .default-menu.mainmenu li.haschild > a.ropen span:after, .menu-wrapper .default-menu.mainmenu li.haschild > h3.ropen span:after {
    content: "\f106";
    color: #fff;
  }
  .header-float .menu-wrapper .default-menu.mainmenu .menulevel, .menu-wrapper .default-menu.mainmenu .menulevel {
    position: static;
    margin: 0;
    box-shadow: none;
    width: 100%;
    padding: 0;
    display: none;
    opacity: 1;
    visibility: visible;
    transform: translate(0);
    transition: none;
  }
  .header-float .menu-wrapper .default-menu.mainmenu .menulevel li.haschild > a:before, .header-float .menu-wrapper .default-menu.mainmenu .menulevel li.haschild > h3:before, .menu-wrapper .default-menu.mainmenu .menulevel li.haschild > a:before, .menu-wrapper .default-menu.mainmenu .menulevel li.haschild > h3:before {
    line-height: 50px;
  }
  .header-float .menu-wrapper .mainmenu-item, .menu-wrapper .mainmenu-item {
    color: #333;
    display: block;
    padding: 0;
  }
  .header-float .menu-wrapper .mainmenu-item:first-child, .menu-wrapper .mainmenu-item:first-child {
    padding-left: 0;
  }
  .header-float .menu-wrapper .mainmenu-item:last-child, .menu-wrapper .mainmenu-item:last-child {
    padding-right: 0;
  }
  .header-float .menu-wrapper .mainmenu-item a, .menu-wrapper .mainmenu-item a {
    color: #333;
    line-height: 60px;
  }
  .header-float .menu-wrapper .mainmenu-item a:hover, .menu-wrapper .mainmenu-item a:hover {
    color: #01aac1;
  }
  .header-float .menu-wrapper .mainmenu-item .menulevel li:hover a:hover, .header-float .menu-wrapper .mainmenu-item .menulevel li:hover h3:hover, .header-float .menu-wrapper .mainmenu-item .mainmenu a:hover, .header-float .menu-wrapper .mainmenu-item .mainmenu h3:hover, .menu-wrapper .mainmenu-item .menulevel li:hover a:hover, .menu-wrapper .mainmenu-item .menulevel li:hover h3:hover, .menu-wrapper .mainmenu-item .mainmenu a:hover, .menu-wrapper .mainmenu-item .mainmenu h3:hover {
    background: #01aac1;
    color: #fff;
  }
  .header-float .menu-wrapper .mainmenu-item > .menulevel li, .menu-wrapper .mainmenu-item > .menulevel li {
    padding: 0;
  }
  .header-float .menu-wrapper .mainmenu-item > .menulevel li a, .header-float .menu-wrapper .mainmenu-item > .menulevel li h3, .menu-wrapper .mainmenu-item > .menulevel li a, .menu-wrapper .mainmenu-item > .menulevel li h3 {
    display: block;
  }
  .header-float .menu-wrapper .mainmenu-item > .menulevel li > .menulevel a, .menu-wrapper .mainmenu-item > .menulevel li > .menulevel a {
    padding: 0 30px 0 50px;
  }
  .menuoffset {
    max-width: 750px;
    padding: 0 15px;
    margin: 0 auto;
    display: block;
  }
  .menu {
    margin: 0 auto;
  }
  .stuck .mainmenu li a {
    line-height: 60px;
  }
  .mainmenu-2 .menuoffset {
    max-width: 730px;
  }
  .menu-button {
    display: block;
  }
  .topbar {
    height: auto;
    padding: 15px 0;
    text-align: center;
  }
  .topbar .general-info {
    float: none;
  }
  .topbar .general-info .info-box {
    line-height: 24px;
    padding: 0;
    display: block;
  }
  .topbar .socials {
    line-height: 24px;
    text-align: center;
    float: none;
  }
  .topbar-canvas {
    height: 170px;
  }
  .topbar-desktop {
    display: none;
  }
  .topbar-mobile {
    display: block;
  }
  .logo {
    height: 80px;
  }
  .logo img {
    width: 60%;
  }
  .sticked-menu-wrapper {
    top: 130px;
  }
  .mainmenu li a, .mainmenu li h3 {
    line-height: 80px;
  }
  .mainheader, .default-menu.mainmenu {
    height: 80px;
  }
  .menu-button {
    font-size: 20px;
  }
  .stuck.stickymenu-float .mainheader .mainmenu-item:hover a, .stickymenu-float .mainheader .mainmenu-item:hover a {
    color: #fff;
  }
  .stuck.stickymenu-float .mainheader .mainmenu-item a, .stickymenu-float .mainheader .mainmenu-item a {
    color: #333;
  }
  .sticked-menu-wrapper .stuck-nonebg {
    background: #052035;
  }
  .sticked-menu-wrapper .stuck-nonebg .mainheader {
    background: none;
  }
  .sticked-menu-wrapper .stuck-nonebg .mainmenu {
    height: auto;
    padding: 0;
    margin: 0 auto;
  }
  .sticked-menu-wrapper .stuck.stickymenu-float .mainheader .logo, .sticked-menu-wrapper .stickymenu-float .mainheader .logo {
    margin: 0;
  }
  .sticked-menu-wrapper .stuck.stickymenu-float .mainheader .menulevel, .sticked-menu-wrapper .stickymenu-float .mainheader .menulevel {
    background: #fff;
  }
  .sticked-menu-wrapper .stuck.stickymenu-float .mainheader .mainmenu-item:hover a, .sticked-menu-wrapper .stickymenu-float .mainheader .mainmenu-item:hover a {
    color: #333;
  }
  .sticked-menu-wrapper .stuck.stickymenu-float .mainheader .mainmenu-item:hover > a, .sticked-menu-wrapper .stickymenu-float .mainheader .mainmenu-item:hover > a {
    background: #01aac1;
    color: #fff;
  }
  .sticked-menu-wrapper .stuck.stickymenu-float .mainheader .mainmenu-item:last-child, .sticked-menu-wrapper .stickymenu-float .mainheader .mainmenu-item:last-child {
    padding: 0;
  }
  .sticked-menu-wrapper .stuck.stickymenu-float .mainheader .mainmenu-item li a:hover, .sticked-menu-wrapper .stickymenu-float .mainheader .mainmenu-item li a:hover {
    background: #01aac1;
    color: #fff;
  }
  .sticked-menu-wrapper .stuck.stickymenu-float .accent-bg .menu-button, .sticked-menu-wrapper .stickymenu-float .accent-bg .menu-button {
    margin-right: 0;
  }
}

@media (max-width: 479px) {
  .header-float {
    position: static;
    background: #052035;
  }
  .header-float .container-fluid.offset {
    padding: 0 15px;
  }
}

@media (max-width: 320px) {
  .stuck .mainheader, .mainheader, .mainheader.small-mainheader {
    height: auto;
  }
  .mainheader .socials.float-right {
    float: left;
    margin-bottom: 20px;
  }
  .mainheader .socials.float-right .social-item-1 {
    line-height: 1;
  }
  .header-float .menu-wrapper {
    top: 130px;
  }
  .mainheader.small-mainheader .menu-wrapper {
    top: 120px;
  }
}

.shop-detail-wrapper {
  padding-top: 10px;
  margin-bottom: 60px;
}

.product-detail .product-info .name {
  font-size: 30px;
  font-weight: 700;
  color: #052035;
  margin-bottom: 47px;
}

.product-detail .product-info .price {
  font-size: 18px;
  color: #052035;
}

.product-detail .product-info .product-intro {
  margin: 20px 0 85px;
}

.product-detail .product-info .buy-wrapper .aubtn {
  border-color: #01aac1;
}

.product-detail .product-info .product-moredetail {
  margin-top: 40px;
}

.product-detail .product-info .product-moredetail .product-moredetail-nav .product-moredetail-item {
  margin-right: 37px;
}

.product-detail .product-info .product-moredetail .product-moredetail-nav .product-moredetail-item:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: transparent;
  width: 100%;
  height: 1px;
}

.product-detail .product-info .product-moredetail .product-moredetail-nav .product-moredetail-item a {
  font-weight: 700;
  color: #333;
  line-height: 50px;
  padding: 0;
  background: none;
  border: none;
  display: inline-block;
  position: relative;
}

.product-detail .product-info .product-moredetail .product-moredetail-nav .product-moredetail-item.active a, .product-detail .product-info .product-moredetail .product-moredetail-nav .product-moredetail-item:hover a {
  color: #01aac1;
}

.product-detail .product-info .product-moredetail .product-moredetail-nav .product-moredetail-item.active:after, .product-detail .product-info .product-moredetail .product-moredetail-nav .product-moredetail-item:hover:after {
  background: #01aac1;
}

.product-detail .product-info .product-moredetail .tab-content {
  padding: 15px 0;
}

.product-thumbs {
  border: none;
}

.product-thumb {
  display: inline-block;
  width: 70px;
  height: 70px;
  overflow: hidden;
  box-shadow: 0px 1px 6.65px 0.35px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 0 20px 0 0;
  padding: 0;
}

.product-thumb img {
  max-width: 100%;
}

.product-thumb.active {
  border: 5px solid #01aac1;
}

.product-thumb .nav-link {
  border: none;
  padding: 0;
  border-radius: 0;
}

.product-main-imgs {
  margin-bottom: 20px;
  box-shadow: 0px 1px 6.65px 0.35px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.product-main-imgs img {
  max-width: 100%;
}

.cart {
  padding: 30px 30px 40px 30px;
  margin-bottom: 55px;
}

.cart .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.44;
  color: #333;
  margin-bottom: 25px;
}

.cart .products {
  padding-right: 85px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
}

.cart .product {
  box-shadow: none;
  text-align: left;
  padding: 10px 0 20px 22px;
  margin-bottom: 0;
}

.cart .product .remove-btn {
  color: #acacac;
  padding-top: 5px;
  margin-left: -22px;
  float: left;
  cursor: pointer;
}

.cart .product .product-info {
  font-size: 15px;
  line-height: 1.73;
  float: left;
  margin: 0;
}

.cart .product .product-info .name {
  color: #052035;
  font-weight: 500;
}

.cart .product .product-info .total-price {
  color: #666;
}

.cart .product .product-img {
  float: right;
  margin-right: -85px;
  line-height: 0;
  box-shadow: 0px 1px 6.65px 0.35px rgba(0, 0, 0, 0.1);
}

.cart .product .product-img img {
  max-width: 100%;
}

.cart .subtotal {
  font-size: 18px;
  line-height: 1.44;
  color: #333;
  text-align: right;
}

.cart .subtotal span {
  color: #666;
}

.cart .group-btn {
  text-align: right;
  margin-top: 25px;
}

.cart .aubtn {
  line-height: 50px;
  margin-left: 13px;
}

.cart .aubtn.checkout {
  background: #666;
  color: #fff;
}

.related-product {
  margin-top: 30px;
}

.related-product .section-title {
  margin-bottom: 20px;
}

@media (max-width: 479px) {
  .product-detail .product-info {
    margin-top: 40px;
  }
  .product-detail .product-info .name {
    margin-bottom: 30px;
  }
}

.project-detail-wrapper {
  padding: 5px 0 40px;
}

.project-carousel-nav-wrapper {
  height: 121px;
  display: table;
  float: right;
}

.project-carousel-nav-wrapper .nav {
  display: table-cell;
  vertical-align: middle;
}

.project-carousel-nav-wrapper .aubtn {
  width: 32px;
  height: 32px;
  line-height: 28px;
  text-align: center;
  border: 2px solid #ccc;
  color: #ccc;
  font-size: 13px;
  border-radius: 3px;
}

.project-detail .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  color: #333;
  margin-bottom: 20px;
}

.project-info-wrapper {
  padding-bottom: 20px;
}

.project-info-wrapper .project-infos .info {
  font-size: 15px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 10px;
}

.project-content-wrapper .project-content p {
  font-size: 15px;
  line-height: 1.58;
  color: #666;
  margin-bottom: 30px;
}

.project-imgs {
  margin-top: 3px;
}

.project-detail-img {
  text-align: center;
  margin-bottom: 40px;
}

.project-detail-img img {
  max-width: 100%;
}

@media (max-width: 479px) {
  .project-carousel-nav-wrapper {
    display: block;
    float: none;
    height: auto;
    margin-bottom: 25px;
    text-align: center;
  }
  .project-carousel-nav-wrapper .nav {
    display: block;
  }
}

.button-group {
  padding-top: 5px;
  text-align: right;
}

.button-group .aubtn {
  font-size: 15px;
  color: #888;
  padding: 0 16px 0 17px;
}

.button-group .aubtn:hover {
  color: #01aac1;
}

.button-group .aubtn:first-child {
  padding-left: 0;
}

.button-group .aubtn:last-child {
  padding-right: 0;
}

.button-group .aubtn.is-checked {
  color: #01aac1;
}

.projects {
  text-align: center;
  padding: 45px 0 35px;
}

.projects-1 {
  padding: 65px 0 50px;
}

.projects-2 {
  padding: 70px 0 48px;
}

.project {
  display: inline-block;
  float: none;
  padding-bottom: 10px;
}

.project .project-img {
  display: inline-block;
  overflow: hidden;
}

.project .project-img img {
  max-width: 110%;
  transition: all 0.3s ease-in-out;
}

.project .project-info {
  text-align: left;
  padding: 13px 0 23px;
}

.project .project-info .name {
  margin-bottom: 12px;
}

.project .project-info .name a {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  color: #333;
  display: block;
}

.project .project-info .cate {
  font-size: 15px;
  line-height: 1.2;
  color: #9f9f9f;
}

.project-1 {
  position: relative;
  padding: 0;
  margin-bottom: 30px;
}

.project-1 .project-info {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(5, 32, 53, 0.7);
  transition: all 0.3s ease-in-out;
  padding: 0 15px;
  opacity: 0;
  transform: scale(0.8);
}

.project-1 .project-info:hover {
  opacity: 1;
  transform: scale(1);
}

.project-1 .project-info .name, .project-1 .project-info .rmbtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.project-1 .project-info .name {
  font-size: 20px;
  margin-top: -35px;
}

.project-1 .project-info .name a {
  color: #fff;
}

.project-1 .project-info .rmbtn {
  font-size: 13px;
  font-weight: 700;
  line-height: 35px;
  padding: 0 15px;
  background: #01aac1;
  color: #fff;
  margin-top: 17px;
  transition: all 0.3s ease-in-out;
}

.project-1 .project-info .rmbtn:hover {
  background: #fff;
  color: #01aac1;
}

@media (max-width: 991px) {
  .button-group {
    text-align: center;
  }
}

@media (max-width: 479px) {
  .button-group .aubtn {
    display: block;
    padding: 0;
    margin-bottom: 15px;
  }
}

.rev_slider .rev_slider {
  width: 100%;
  padding: 0 15px;
}

.rev_slider .tp-caption.section-title-style-1 span {
  border-bottom: 3px solid #fff;
  display: inline-block;
}

.rev_slider .tp-caption.section-title-style-1.darker-color span {
  border-bottom-color: #052035;
  display: inline-block;
}

.rev_slider .section-title span {
  font-weight: 500;
}

.rev_slider .section-title-moredes {
  font-size: 20px;
  line-height: 1.6;
  color: #f2f2f2;
}

.rev_slider .section-title-moredes.darker-color {
  color: #112631;
}

.rev_slider .section-title-moredes-style-2 {
  color: #f2f2f2;
}

.rev_slider .quote {
  font-size: 20px;
  line-height: 1.5;
  color: #fff;
}

.rev_slider .quote span {
  font-family: Impact;
  font-size: 100px;
  padding-right: 15px;
  position: relative;
  top: 45px;
  color: #01aac1;
}

.rev_slider .author {
  color: #ccc;
}

.rev_slider .tparrows.tp-leftarrow, .rev_slider .tp-rightarrow.tparrows {
  width: 50px;
  height: 50px;
  text-align: center;
  background: #052035;
}

.rev_slider .tparrows.tp-leftarrow:before, .rev_slider .tp-rightarrow.tparrows:before {
  font-family: FontAwesome;
  font-size: 20px;
  line-height: 50px;
  color: #01aac1;
}

.rev_slider .tp-leftarrow:before {
  content: '\f060';
}

.rev_slider .tp-rightarrow:before {
  content: '\f061';
}

@media (max-width: 479px) {
  .rev_slider .tparrows.tp-leftarrow, .rev_slider .tp-rightarrow.tparrows {
    display: none;
  }
  .rev_slider .aubtn {
    line-height: 35px;
    font-size: 13px;
  }
  .rev_slider .quote span {
    font-size: 50px;
    top: 20px;
  }
}

.service-detail-wrapper {
  margin: 30px 0 75px;
}

.services-cate {
  margin-bottom: 70px;
}

.services-cate .services-cate-item {
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  border: none;
  float: none;
}

.services-cate .services-cate-item.active a, .services-cate .services-cate-item.active a:hover, .services-cate .services-cate-item.active a:focus {
  color: #01aac1;
  border: none;
  border-left: 5px solid #01aac1;
  background: #fff;
}

.services-cate .services-cate-item a {
  font-weight: 700;
  line-height: 50px;
  display: block;
  padding: 0 24px;
  border: none;
  border-left: 5px solid #e4e4e4;
  color: #333;
  transition: all 0.3s ease-in-out;
}

.services-cate .services-cate-item a:hover, .services-cate .services-cate-item a:focus {
  color: #01aac1;
  border: none;
  border-left: 5px solid #01aac1;
  background: #fff;
}

.service-detail {
  margin-bottom: 25px;
}

.service-detail-content .des {
  margin-bottom: 45px;
}

.service-detail-content .des p {
  color: #666;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .service-sidebar {
    margin-top: 40px;
  }
  .service-sidebar .testimonials-style-3 {
    padding: 30px 18px;
  }
}

@media (max-width: 479px) {
  .service-sidebar .testimonials-style-3 .testimonial {
    padding-left: 40px;
  }
  .service-sidebar .testimonials-style-3 .testimonial .tes-content:before {
    left: -40px;
  }
}

.services-wrapper {
  position: relative;
  padding-bottom: 80px;
}

.services {
  padding: 65px 0 70px;
}

.services-float {
  padding: 0;
  margin-bottom: -83px;
  position: relative;
  left: 0;
  top: -53px;
}

.services-style-2 {
  margin: 30px 0;
}

.services-style-2 .service {
  margin-bottom: 70px;
}

.services-style-2 .service-content {
  padding: 25px 60px 0 0;
  background: none;
}

.services-style-2 .service-content .title a {
  color: #333;
}

.services-style-2 .service-content .des {
  color: #666;
  margin-bottom: 15px;
}

.service {
  background: #fff;
  margin-bottom: 30px;
}

.service:hover .service-img img {
  filter: saturate(140%) sepia(10%);
}

.service.white-bg {
  padding: 25px 0 30px;
  background: #fff;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
}

.service.white-bg .service-content {
  padding: 0 40px 15px;
  background: none;
}

.service.white-bg .service-content .title a {
  color: #052035;
}

.service.white-bg .service-content .des {
  color: #666;
}

.service-img {
  line-height: 0;
  display: block;
  overflow: hidden;
}

.service-img img {
  transform: scale(1);
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}

.service-icon {
  padding: 25px 0;
  text-align: center;
}

.service-content .title,
.related-service .title {
  margin: 0 0 12px 0;
}

.service-content .title a,
.related-service .title a {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.44;
}

.service-content .des,
.related-service .des {
  line-height: 1.57;
}

.service-content {
  background: #052035;
  padding: 20px 30px 15px;
}

.service-content .title a {
  display: block;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.service-content .title a:hover {
  color: #01aac1;
}

.service-content .des {
  color: #e4e4e4;
}

.related-service {
  margin-bottom: 30px;
}

.related-service .title a {
  color: #333;
}

.related-service .des {
  color: #666;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .services-wrapper {
    margin-bottom: -50px;
  }
  .service.white-bg {
    padding: 20px 0 30px;
  }
  .service.white-bg .service-content {
    padding: 15px 10px;
  }
  .service-icon {
    transform: scale(0.8);
  }
}

@media (max-width: 479px) {
  .services {
    padding: 40px 0 0;
  }
  .services-float {
    position: static;
    margin: 50px 0 0;
  }
}

.shop-list-wrapper {
  padding-top: 10px;
  margin-bottom: 60px;
}

.shop-list-wrapper .pagimation {
  margin-top: 10px;
}

.shop-cate-wrapper {
  padding-bottom: 65px;
}

.shop-cate-wrapper-1 {
  padding-bottom: 0px;
}

.shop-list .pagination {
  margin-top: 10px;
}

.shop-cate {
  border-left: 5px solid #e4e4e4;
}

.shop-cate .shop-cate-item {
  font-size: 18px;
  position: relative;
}

.shop-cate .shop-cate-item:before {
  content: "";
  width: 5px;
  height: 58px;
  background: #e4e4e4;
  position: absolute;
  top: 0;
  left: -5px;
}

.shop-cate .shop-cate-item a {
  line-height: 1.44;
  padding: 0 24px;
  display: block;
  color: #999;
  transition: all 0.2s ease-in-out;
}

.shop-cate .shop-cate-item:hover > a, .shop-cate .shop-cate-item.active > a {
  color: #01aac1;
}

.shop-cate .shop-cate-item:hover:before, .shop-cate .shop-cate-item.active:before {
  background: #01aac1;
}

.shop-cate .shop-cate-item .shop-cate-level {
  margin-top: -10px;
}

.shop-cate .shop-cate-item .shop-cate-level .shop-cate-level-item:hover > a, .shop-cate .shop-cate-item .shop-cate-level .shop-cate-level-item.active > a {
  color: #01aac1;
}

.shop-cate .shop-cate-item .shop-cate-level .shop-cate-level-item:hover:before, .shop-cate .shop-cate-item .shop-cate-level .shop-cate-level-item.active:before {
  background: #01aac1;
}

.shop-cate .shop-cate-item > a {
  line-height: 52px;
}

.shop-sidebar-banner {
  padding: 25px 65px 35px;
}

.shop-sidebar-banner .shop-sidebar-banner-content .title {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  color: #999;
  margin: 50px 0 30px;
}

.shop-sidebar-banner .shop-sidebar-banner-content .aubtn {
  font-weight: 700;
  border-color: #333;
}

.shop-sidebar-banner .shop-sidebar-banner-content .aubtn:hover {
  background: #fff;
  color: #333;
}

.rated-products {
  margin-top: 55px;
}

.rated-products .section-title {
  margin-bottom: 35px;
}

.rated-products .rated-product {
  padding-left: 113px;
  font-size: 18px;
  line-height: 1.44;
  margin-bottom: 20px;
}

.rated-products .rated-product img {
  float: left;
  margin-left: -113px;
  box-shadow: 0px 1px 6.65px 0.35px rgba(0, 0, 0, 0.1);
}

.rated-products .rated-product .product-info {
  padding-top: 10px;
}

.rated-products .rated-product .name {
  margin-bottom: 0;
  font-size: 18px;
}

.rated-products .rated-product .name a {
  color: #888;
}

.rated-products .rated-product .name:hover {
  color: #888;
}

.rated-products .rated-product .price {
  color: #052035;
}

.product {
  font-size: 18px;
  text-align: center;
  padding-bottom: 30px;
  margin-bottom: 50px;
  box-shadow: 0px 1px 6.65px 0.35px rgba(0, 0, 0, 0.1);
  position: relative;
}

.product img {
  max-width: 100%;
}

.product .sale {
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  padding: 0 13px;
  color: #fff;
  background: #01aac1;
  position: absolute;
  top: 55px;
  right: 37px;
}

.product .product-info {
  margin: 16px 0 15px;
}

.product .product-info .name {
  font-size: 18px;
}

.product .name a {
  line-height: 1.44;
  font-weight: 700;
  color: #052035;
}

.product .name a:hover {
  color: #052035;
}

.product .price {
  color: #052035;
}

.product .price .old-price {
  font-size: 13px;
  color: #7e7e7e;
  text-decoration: line-through;
}

.product .aubtn {
  font-size: 14px;
  font-weight: 700;
  border-color: #999;
}

.product .aubtn:hover {
  color: #052035;
  border-color: #052035;
}

.ordering {
  position: relative;
}

.order-nav {
  margin-bottom: 23px;
}

.result, .current-cate {
  color: #333;
  line-height: 35px;
}

.result {
  float: left;
}

.current-cate {
  text-align: right;
  display: inline-block;
  float: right;
  cursor: pointer;
}

.current-cate:after {
  content: "\f107";
  font-family: FontAwesome;
  padding-left: 10px;
}

.nav.nav-tabs.order-box {
  width: 200px;
  text-align: right;
  border: 1px solid #666;
  color: #333;
  background: #fff;
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 10;
  display: none;
}

.nav.nav-tabs.order-box > li.nav-item.order-item > a.nav-link {
  border: none;
}

.nav.nav-tabs.order-box > li.nav-item.order-item > a.nav-link:hover {
  background-color: #fff;
}

.nav.nav-tabs.order-box li.order-item {
  background: none;
  width: 100%;
  float: none;
  text-align: center;
  padding: 0;
  margin: 0;
}

.nav.nav-tabs.order-box li.order-item a {
  color: #333;
  display: block;
  line-height: 35px;
  padding: 10px 15px;
  cursor: pointer;
  margin: 0;
}

.nav.nav-tabs.order-box li.order-item.active {
  background: none;
}

.nav.nav-tabs.order-box li.order-item:hover {
  background: none;
}

.nav-link {
  display: block;
}

@media (max-width: 991px) {
  .shop-list {
    margin-bottom: 55px;
  }
}

.stats {
  padding: 58px 0 55px;
}

.stats-pad-1 {
  padding: 0  0 75px;
}

.stat-item {
  padding: 20px 0 20px 100px;
}

.stat-icon {
  margin-left: -98px;
  float: left;
}

.stat-content .stat-number {
  font-size: 36px;
  line-height: 1;
  font-weight: 700;
  color: #052035;
}

.stat-content .title {
  font-size: 13px;
  font-weight: 700;
  line-height: 1.69;
  color: #999;
  margin: 10px 0 0;
}

@media (max-width: 991px) {
  .stats-pad-1 {
    padding: 25px 0 80px;
  }
}

.shoping-cart-wrapper {
  margin-bottom: 60px;
}

.autable {
  font-weight: 700;
  margin-bottom: 60px;
}

.autable thead {
  color: #052035;
  background: #ededed;
}

.autable thead tr th {
  height: 53px;
  line-height: 53px;
  border: none;
}

.autable tbody tr:first-child td {
  border: none;
}

.autable .product {
  padding: 0 0 0 120px;
  width: 100%;
  text-align: left;
  box-shadow: none;
  margin-bottom: 0;
}

.autable .product img {
  max-height: 100%;
  margin-left: -120px;
  float: left;
}

.autable .product-name {
  font-size: 14px;
  line-height: 1.7;
  display: block;
  color: #333;
  margin-top: 40px;
}

.autable .price {
  color: #888;
  margin: 0;
}

.autable .quantity-btn {
  font-weight: 500;
  margin-right: 0;
}

.autable .total-wrapper {
  width: 100%;
  padding-right: 25px;
}

.autable .total-wrapper .total {
  float: left;
  color: #888;
}

.autable .total-wrapper .remove-btn {
  float: right;
  color: #888;
  padding: 10px;
  cursor: pointer;
  margin-top: -10px;
}

.code-form .form-left {
  float: left;
  display: inline-block;
}

.couponcode {
  width: 200px;
  line-height: 50px;
  padding: 0;
  display: inline-block;
}

.couponcode input {
  color: #333;
  width: 100%;
  line-height: 50px;
  height: 50px;
  padding: 0 20px;
  border: none;
  background: none;
}

.couponcode ::-webkit-input-placeholder {
  color: #b4b4b4;
}

.couponcode :-moz-placeholder {
  color: #b4b4b4;
  opacity: 1;
}

.couponcode ::-moz-placeholder {
  color: #b4b4b4;
  opacity: 1;
}

.couponcode :-ms-input-placeholder {
  color: #b4b4b4;
}

.submit-btn {
  margin-left: 14px;
}

.update-cart {
  float: right;
}

.cart-total-wrapper {
  padding: 80px 0 40px;
}

.cart-total-wrapper .section-title {
  margin-bottom: 35px;
}

.cart-total {
  padding: 30px 43px 45px;
  border: 5px solid #01aac1;
}

.cart-total .subtotal, .cart-total .total {
  line-height: 1.86;
  color: #333;
  margin-bottom: 15px;
  font-weight: 700;
}

.cart-total .subtotal .number, .cart-total .total .number {
  color: #666;
}

.cart-total .services-des {
  color: #666;
}

.cart-total .auform.form-checkout .title {
  font-size: 14px;
  line-height: 1.42;
  font-weight: 700;
  margin: 20px 0 13px;
  color: #333;
}

.cart-total .auform.form-checkout .select-group {
  display: inline-block;
  width: inherit;
}

.cart-total .auform.form-checkout input, .cart-total .auform.form-checkout select {
  font-size: 13px;
  height: 40px;
  padding: 0 12px;
  line-height: 40px;
  border: none;
  border-radius: 3px;
  background: none;
}

.cart-total .auform.form-checkout input .notchoosen, .cart-total .auform.form-checkout select .notchoosen {
  color: #666;
}

.cart-total .auform.form-checkout .select-group, .cart-total .auform.form-checkout input {
  background: #ededed;
  margin-right: 20px;
}

.cart-total .auform.form-checkout ::-webkit-input-placeholder {
  color: #666;
}

.cart-total .auform.form-checkout :-moz-placeholder {
  color: #666;
  opacity: 1;
}

.cart-total .auform.form-checkout ::-moz-placeholder {
  color: #666;
  opacity: 1;
}

.cart-total .auform.form-checkout :-ms-input-placeholder {
  color: #666;
}

.cart-total .auform.form-checkout .select-button {
  line-height: 40px;
  right: 13px;
  color: #999;
  z-index: 10;
}

.cart-total .auform.form-checkout .total {
  margin-bottom: 20px;
}

.cart-total .auform.form-checkout .aubtn {
  line-height: 50px;
  font-weight: 700;
  display: block;
  margin-left: 5px;
  text-align: center;
  background: #052035;
  color: #fff;
  position: relative;
}

.cart-total .auform.form-checkout .aubtn .fa-arrow-right {
  padding-left: 10px;
}

.cart-total .auform.form-checkout .aubtn input {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 50px;
  background: none;
}

@media (max-width: 479px) {
  table.autable thead {
    font-size: 13px;
  }
  table.autable .quantity {
    display: none;
  }
  table.autable .product {
    padding-left: 0;
  }
  table.autable .product img {
    display: none;
  }
  table.autable tbody tr td {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  table.autable .product-name {
    font-size: 13px;
    font-weight: 500;
    margin: 10px 0 0 15px;
  }
  table.autable .total-wrapper {
    padding-right: 10px;
  }
  table.autable .total-wrapper .remove-btn {
    margin-right: -7px;
  }
  .code-form .form-left {
    float: none;
    margin-bottom: 20px;
  }
  .update-cart {
    float: none;
    margin-left: 0;
  }
}

@media (max-width: 320px) {
  table.autable .price-wrapper {
    display: none;
  }
  .code-form .couponcode {
    margin-bottom: 20px;
  }
  .code-form .aubtn.submit-btn {
    margin-left: 0;
  }
  .cart-total {
    padding: 15px;
  }
}

.testimonials-wrapper-pad-1 {
  padding: 75px 0 100px;
}

.testimonials-wrapper-dark-bg .tes-content {
  color: #fff;
}

.testimonials-wrapper-dark-bg .author {
  color: #ccc;
}

.carousel-wrapper {
  padding: 120px 200px 107px;
  width: 100%;
  overflow: hidden;
}

.carousel-wrapper:hover .carousel-nav {
  opacity: 1;
}

.carousel-wrapper:hover .carousel-nav .prev-btn, .carousel-wrapper:hover .carousel-nav .next-btn {
  transform: translateX(0);
}

.carousel-wrapper .carousel {
  position: relative;
}

.carousel-wrapper-pad-1 {
  padding: 0 200px;
}

.testimonials-style-2 .testimonial {
  background: none;
}

.testimonials-style-2 .testimonial .tes-content {
  font-size: 20px;
  line-height: 1.5;
  color: #fff;
  margin-bottom: 30px;
}

.testimonials-style-2 .testimonial .author {
  color: #ccc;
}

.testimonials-style-3 {
  padding: 0;
}

.testimonials-style-3 .testimonial {
  padding: 10px 0 0 50px;
  text-align: left;
}

.testimonials-style-3 .tes-content {
  font-size: 18px;
  line-height: 1.67;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

.testimonials-style-3 .tes-content:before {
  left: -45px;
}

.testimonials-pad-1 {
  padding: 45px 220px 30px;
}

.testimonials-pad-2 {
  padding: 48px 18px 43px;
}

.testimonials-blue-grey-bg {
  background: #f6f6f6;
}

.testimonials-blue-grey-bg .testimonial {
  background: #f6f6f6;
}

.carousel-nav {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.carousel-nav .prev-btn, .carousel-nav .next-btn {
  font-size: 25px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  border-radius: 100%;
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}

.carousel-nav .prev-btn {
  left: -20px;
  transform: translateX(-50px);
}

.carousel-nav .next-btn {
  right: -20px;
  transform: translateX(50px);
}

.testimonial {
  padding: 70px 80px 45px;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
}

.testimonial-2 {
  padding: 65px 80px 60px;
}

.tes-content {
  font-size: 25px;
  line-height: 1.4;
  color: #333;
  margin-bottom: 35px;
  position: relative;
}

.tes-content:before {
  content: '“';
  font-family: Impact;
  font-size: 100px;
  color: #01aac1;
  position: absolute;
  top: -40px;
  left: -40px;
}

.signature {
  padding: 20px 0 15px;
}

.signature img {
  display: inline-block !important;
  width: auto !important;
}

.author {
  font-weight: 700;
  color: #666;
}

.testimonials-nobg .testimonial {
  background: transparent;
}

@media (max-width: 991px) {
  .carousel-wrapper-pad-1 {
    padding: 0;
  }
  .carousel-wrapper {
    padding: 60px 40px;
  }
  .testimonials-style-3 {
    padding: 0 0 30px;
  }
  .testimonials-pad-1 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .testimonial {
    padding-left: 50px;
    padding-right: 50px;
  }
  .testimonials-style-2 .testimonial .tes-content:before {
    left: -30px;
  }
  .tes-content:before {
    left: -5px;
  }
  .testimonials-wrapper-dark-bg .tes-content:before {
    left: -35px;
  }
}

@media (max-width: 479px) {
  .testimonials-wrapper-pad-1 {
    padding: 0;
  }
  .carousel-wrapper {
    padding: 30px 20px;
  }
  .testimonials-style-3 {
    padding: 0 0 30px;
  }
  .testimonials-style-3 .tes-content {
    font-size: 15px;
  }
  .testimonials-style-3 .tes-content:before {
    left: -50px;
  }
  .testimonials-pad-1 {
    padding-left: 0;
    padding-right: 0;
  }
  .testimonials-style-2 .testimonial .tes-content {
    font-size: 15px;
  }
  .testimonials-wrapper-dark-bg .tes-content:before {
    left: -15px;
  }
  .testimonial {
    padding: 25px 40px;
  }
  .tes-content {
    font-size: 18px;
    margin-bottom: 25px;
  }
  .tes-content:before {
    font-size: 70px;
    top: -27px;
    left: -8px;
  }
  .author {
    font-size: 13px;
  }
}

@media (max-width: 320px) {
  .testimonials-wrapper-dark-bg .tes-content:before {
    left: -17px;
  }
  .tes-content {
    font-size: 14px;
  }
  .tes-content:before {
    font-size: 50px;
    top: -20px;
    left: -20px;
  }
  .author {
    font-size: 11px;
    font-weight: 500;
  }
}

.wcu {
  padding: 50px 0 40px;
}

@media (max-width: 479px) {
  .wcu {
    padding-top: 40px;
  }
}
