.breadcrumb{
  padding: 25px 0;
  background: none;
  &-1 {
    padding-bottom: 20px;
  }
  p {
    font-size: 13px;
    line-height: 1.69;
    a {
      color: $grey-1;
      &:before {
        content:'/';
        padding: 0 10px;
      }
      &:first-child {
         &:before {
           content:'\f015';
           font-family: FontAwesome;
           padding: 0 5px 0 0;
         }
      }
      &:last-child {
        color: #666;
      }
    }

  }

}