.cta {
  padding: 67px 0 70px;
  background: $accent-color;
  position: relative;
  &-pad-1 {
    padding: 35px 0;
    background: $theme-color;
  }
  &-lightgrey-bg {
    background: #e5e5e5;
    .section-title {
      color: $grey-1
    }
    .section-title-moredes {
      color: #666;
    }
    .auform input, .auform select {
      line-height: 50px;
      border: none;
      box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
    }
    .aubtn {
      box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);

    }
  }
  .aubtn {
    input {
      padding: 0 50px;
      background: none;
      margin-bottom: 0;
      color: $white;
      border: none;

      &:hover {
        color: $theme-color;
      }
    }
  }
  &-grey-bg {
    background: $grey-1;
  }
}
.cta-title {
  font-size: 20px;
  color: $white;
  margin: 32px 0 0;
  font-weight: $bold;
}
.getaquotebtn {
  margin: 20px 0 20px 35px;
  &:hover {
    border-color: $white;
  }
}
@include respond-to('medium-tablet') {
  .getaquotebtn {
    margin-left: 0;
  }
  .getaquotebtn-wrapper,.cta-title  {
    text-align: center;
  }
}