.shop-detail-wrapper {
  padding-top: 10px;
  margin-bottom: 60px;
}
.product-detail {
//Product info
  .product-info {
    .name {
      font-size: 30px;
      font-weight: $bold;
      color: $accent-color;
      margin-bottom: 47px;
    }
    .price {
      font-size: 18px;
      color: $accent-color;
    }
    .product-intro {
      margin: 20px 0 85px;
    }
    .buy-wrapper {

      .aubtn {
        border-color: $theme-color;
      }
    }

    // product more detail
    .product-moredetail {
      margin-top: 40px;
      .product-moredetail-nav {
        .product-moredetail-item {
          margin-right: 37px;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            background: transparent;
            width: 100%;
            height: 1px;
          }

          a {
            font-weight: $bold;
            color: $grey-1;
            line-height: 50px;
            padding: 0;
            background: none;
            border: none;
            display: inline-block;
            position: relative;
          }
          &.active, &:hover  {
            a {
              color: $theme-color;
            }
            &:after {
              background: $theme-color;
            }
          }
        }
      }

      .tab-content {
        padding: 15px 0;
      }
    }
  }
}
.product-thumbs {
  border: none;

}
.product-thumb {
  display: inline-block;
  width: 70px;
  height: 70px;
  overflow: hidden;
  box-shadow: 0px 1px 6.65px 0.35px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 0 20px 0 0;
  padding: 0;

  img {
    max-width: 100%;
  }
  &.active {
    border: 5px solid #01aac1;
  }
  .nav-link {
    border: none;
    padding: 0;
    border-radius: 0;
  }
}
.product-main-imgs {
  margin-bottom: 20px;
  box-shadow: 0px 1px 6.65px 0.35px rgba(0, 0, 0, 0.1);
  text-align: center;
  img {
    max-width: 100%;
  }
}

// cart
.cart {
  padding: 30px 30px 40px 30px;
  margin-bottom: 55px;
  .title {
    font-size: 18px;
    font-weight: $bold;
    line-height: 1.44;
    color: $grey-1;
    margin-bottom: 25px;
  }
  .products {
    padding-right: 85px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 20px;
  }
  .product {
    box-shadow: none;
    text-align: left;
    padding: 10px 0 20px 22px;
    margin-bottom: 0;
    .remove-btn {
      color: #acacac;
      padding-top: 5px;
      margin-left: -22px;
      float: left;
      cursor: pointer;
    }
    .product-info {
      font-size: 15px;
      line-height: 1.73;
      float: left;
      margin: 0;
      .name {
        color: $accent-color;
        font-weight: $medium;
      }
      .total-price {
        color: $grey-4;
      }
    }
    .product-img {
      float: right;
      margin-right: -85px;
      line-height: 0;
      box-shadow: 0px 1px 6.65px 0.35px rgba(0, 0, 0, 0.1);
      img {
        max-width: 100%;
      }
    }
  }
  .subtotal {
    font-size: 18px;
    line-height: 1.44;
    color: $grey-1;
    text-align: right;
    span {
      color: $grey-4;
    }
  }
  .group-btn {
    text-align: right;
    margin-top: 25px;
  }
  .aubtn {
    line-height: 50px;
    margin-left: 13px;
    &.checkout {
      background: #666;
      color: $white;
    }
  }
}
.related-product {
  margin-top: 30px;
  .section-title {
    margin-bottom: 20px;
  }
}

//Responsive
@include respond-to('medium-mobile') {
  .product-detail {
    .product-info {
      margin-top: 40px;
      .name {
        margin-bottom: 30px;
      }
    }
  }
}