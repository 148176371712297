.services-wrapper {
  position: relative;
  padding-bottom: 80px;
}
.services {
  padding: 65px 0 70px;
  &-float {
    padding: 0;
    margin-bottom: -83px;
    position: relative;
    left: 0;
    top: -53px;
  }
  &-style-2 {
    margin: 30px 0;
    .service {
      margin-bottom: 70px;
    }
    .service-content {
      padding: 25px 60px 0 0;
      background: none;
      .title a{
        color: $grey-1;
      }
      .des {
        color: #666;
        margin-bottom: 15px;
      }

    }
  }
}
.service {
  background: $white;
  margin-bottom: 30px;
  &:hover {
    .service-img{
      img {
        filter: saturate(140%) sepia(10%);
      }
    }
  }
  &.white-bg {
    padding: 25px 0 30px;
    background: $white;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
    .service-content {
      padding: 0 40px 15px;
      background: none;
      .title a{
        color: $accent-color;
      }
      .des {
        color: #666;
      }
    }
  }
}
.service-img {
  line-height: 0;
  display: block;
  overflow: hidden;
  img {
    transform: scale(1);
    max-width: 100%;
    transition: all 0.3s ease-in-out;
  }
}
.service-icon {
  padding: 25px 0;
  text-align: center;
}
.service-content,
.related-service {
  .title {
    margin: 0 0 12px 0;
    a {
      font-size: 18px;
      font-weight: $bold;
      line-height: 1.44;
    }
  }
  .des {
    line-height: 1.57;
  }
}

.service-content {
  background: $accent-color;
  padding: 20px 30px 15px;
  .title a{
    display: block;
    color: $white;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: $theme-color;
    }
  }
  .des {
    color: #e4e4e4;
  }
}
.related-service {
  margin-bottom: 30px;
  .title a{
    color: $grey-1;
  }
  .des {
    color: $grey-4;
    margin-bottom: 20px;
  }
}

//Responsive
@include respond-to('medium-tablet') {
  .services-wrapper {
    margin-bottom: -50px;
  }
  .service.white-bg  {
    padding: 20px 0 30px;
    .service-content {
      padding: 15px 10px;
    }
  }
  .service-icon{
    transform: scale(0.8);
  }
}
@include respond-to('medium-mobile') {
  .services {
    padding: 40px 0 0;
    &-float {
      position: static;
      margin: 50px 0 0;
    }
  }
}
