//float
.float-left {
  float: left;
}
.float-right {
  float: right;
}

// MARGIN
.mar-1 {
  margin: 20px 0;
}
.mar-2 {
  margin: 35px 0 15px;
}
//MARGIN BOTTOM
.marbt-1 {
  margin-bottom: 45px;
}
.marbt-2 {
  margin-bottom: 20px;
}
.marbt-3 {
  margin-bottom: 30px;
}
.marbt-4 {
  margin-bottom: 25px;
}
//PADDING
.pad-1 {
  padding: 45px 0 50px;
}
.pad-top-1 {
  padding-top: 5px;
}
.pad-right-1 {
  padding-right: 5px;
}
.pad-bottom-1 {
  padding-bottom: 5px;
}
.pad-left-1 {
  padding-left: 5px;
}

//PADDING
//.pad-1 {
//  padding: 55px 0;
//}

//flex box
.row-flex,.row-flex > div[class*='col-'] {
  display: flex;
  flex-wrap: wrap;
  //flex: 1 1 auto;
}
.row-flex > div.col-xs-12 {
  display: block;
}

// Collumns
.fitcol {
  padding: 0;
}
.fitleft {
  padding-left: 0;
}
.fitright {
  padding-right: 0;
}
//align
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
//Table
table.autable > tbody > tr > td, table.autablee > tbody > tr > th, table.autable > tfoot > tr > td,
table.autable > tfoot > tr > th, table.autable > thead > tr > td, table.autable > thead > tr > th {
  padding: 0 15px;
  vertical-align: middle;
  &.fitleft {
    padding-left: 0;
  }
  &.fitright {
    padding-right: 0;
  }
}

//Charater
.uppercase {
  text-transform: uppercase;
}
.no-decoration {
  text-decoration: none;
}