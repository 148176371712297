.grey-bg {
  background: $grey-1;
}
.grey-bg-1 {
  background: #f2f2f2;
}
.blue-grey-bg {
  background: $blue-grey;
}
.white-bg {
  background: $white;
}
.darkblue-bg {
  background: rgba(#132535, 0.95);
}
.accent-bg {
  background: $accent-color;
}