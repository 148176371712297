// Blog detail wrapper
.blog-detail-wrapper {
  margin: 30px 0 85px;
}
// Blog sidebar
.blog-sidebar {
  .blog-sidebar-banner {
    padding: 15px;
    display: table;
    text-align: center;
    background-color: transparent;
    height: 246px;
    position: relative;
    .title {
      font-size: 18px;
      line-height: 1.33;
      color: $theme-color;
      display: table-cell;
      vertical-align: middle;
      margin-bottom: 0;
    }
    .overlay {
      display: block;
      width: 100%;
      height: 100%;
      background: none;
      position: absolute;
      top:  0;
      left: 0;
      z-index: 2;
    }
  }
}
.blog-cate {
  margin-bottom: 50px;
  .section-title-underline {
    border-bottom-color: $grey-1;
    border-bottom-width: 1px;
    line-height: 1.2;
  }
  .blog-cate-item {
    font-size: 16px;
    line-height: 2.5;
    font-weight: $bold;
    a {
      display: block;
      color: $grey-1;
    }
    &:hover {
      a {
        color: $theme-color;
      }
    }
  }
}


//Blog detail
.blog-detail {
  margin-bottom: 65px;
  //author
  .other-author {
    background: #e5e5e5;
    line-height: 55px;
    font-weight: $medium;

    .avt {
      display: inline-block;
      line-height: 0;
    }
    .author-info {
      line-height: 1.57;
      color: $grey-4;
      padding-left: 20px;
      display: inline-block;
      margin-bottom: 0;
      .name, .job {
        color: $grey-1;
      }
      .name:after {
        content: "/";
        padding: 0 13px 0 10px;
      }
    }
  }
}


//Reply
.reply {
  .section-title {
    margin-bottom: 13px;
    color: #333b47;
  }
  .formnote {
    line-height: 1.7;
    color: $grey-4;
    margin-bottom: 35px;
  }
  .auform {
    input, textarea  {
      color: $grey-4;
      border: 1px solid #dedede;
      border-radius: 3px;
      background: #f2f2f2;
    }

    textarea {
      width: 100%;
      height: 171px;
      line-height: 1.7;
      text-align: justify;
    }
  }
  .submit-btn {
    margin: 0;
  }

}

//Comments
.cmts {
  padding: 35px 0 45px;
  .section-title {
    color: #333b47;
  }
  .cmt {
    padding-left: 70px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ececec;
    .cmt-avt {
      margin-left: -70px;
      float: left;
    }
    .cmt-content {
      line-height: 1.57;
      color: $grey-4;
      margin-bottom: 15px;
    }
    .cmt-reply {
      border: none;
    }
  }
  .cmt-reply {
    margin-top: 30px;
  }
  .author {
    font-size: 13px;
    color: #999;
    font-weight: $bold;
    margin-right: 28px;
    line-height: 1.84;
  }
 }

// Responsive
@include respond-to('medium-tablet') {
  .blog-sidebar {
    padding-top: 50px;
  }
}

@include respond-to("medium-mobile") {
}