.button-group {
  padding-top: 5px;
  text-align: right;
  .aubtn {
    font-size: 15px;
    color: #888;
    padding: 0 16px 0 17px;
    &:hover {
      color: $theme-color
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    &.is-checked {
      color: $theme-color;
    }
  }
}
.projects {
  text-align: center;
  padding: 45px 0 35px;
  &-1 {
    padding: 65px 0 50px;
  }
  &-2 {
    padding: 70px 0 48px;
  }
}
.project{
  display: inline-block;
  float: none;
  padding-bottom: 10px;
  .project-img {
    display: inline-block;
    overflow: hidden;
    img {
      max-width: 110%;
      transition: all 0.3s ease-in-out;
    }
  }
  .project-info {
    text-align: left;
    padding: 13px 0 23px;
    .name {
      margin-bottom: 12px;
      a {
        font-size: 18px;
        font-weight: $bold;
        line-height: 1;
        color: #333;
        display: block;
      }
    }
    .cate {
      font-size: 15px;
      line-height: 1.2;
      color: #9f9f9f;
    }
  }
}

.project-1 {
  position: relative;
  padding: 0;
  margin-bottom: 30px;
  .project-info {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba($accent-color, 0.7);
    transition: all 0.3s ease-in-out;
    padding: 0 15px;
    opacity: 0;
    transform: scale(0.8);
    &:hover {
      opacity: 1;
      transform: scale(1);
    }
    .name, .rmbtn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    .name {
      font-size: 20px;
      margin-top: -35px;
      a {
        color :$white;
      }
    }
    .rmbtn {
      font-size: 13px;
      font-weight: $bold;
      line-height: 35px;
      padding: 0 15px;
      background: $theme-color;
      color: $white;
      margin-top: 17px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: $white;
        color: $theme-color;
      }
    }
  }
}
@include respond-to('medium-tablet') {
  .button-group {
    text-align: center;
  }
}
@include respond-to('medium-mobile') {
  .button-group {
    .aubtn {
      display: block;
      padding: 0;
      margin-bottom: 15px;
    }
  }
 }