.clients {
  background: rgba(#ededed, 0.7);
  margin-bottom: 10px;
}
.logo-group {
  padding: 84px 0 75px;
}
.logo-item {
  width: 20%;
  display: inline-block;
  list-style: none;
  float: left;
  text-align: center;
  img {
    max-width: 100%;
  }
}