// About
.about {
  margin: 10px 0 130px;
}
.about-img {
  line-height: 0;
  text-align: center;
  img {
    max-width: 100%;
  }
}
.about-content {
  .section-title {
    margin-bottom: 35px;
  }
  .des {
    p {
      color: $grey-4;
      margin-bottom: 20px;
    }
  }
}

//Member
.members-wrapper {
  padding: 50px 0 70px;
  display: inline-block;
  .section-title-underline {
    border-bottom-color: $grey-1;
    line-height: 1;
  }

}
.member {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  display: inline-block;
}
.member-info-wrapper {
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: transparent;
  transition: all 0.4s ease-in-out;
}
.member-info-hoverbg {
  width: 100%;
  background: rgba($accent-color, 0.95);
}
.member-info {
  padding: 25px;
  .name {
    font-size: 18px;
    line-height: 1.44;
    font-weight: $bold;
    color: $white;
    margin-bottom: 5px;
  }
  .job {
    color: $theme-color;
    margin-bottom: 0;
  }
}

//responsive
@include respond-to('medium-mobile') {
  .about {
    margin-bottom: 50px;
  }
}