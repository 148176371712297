.rev_slider {
  .rev_slider {
    width: 100%;
    padding: 0 15px;
  }

  .tp-caption.section-title-style-1 {
    span {
      border-bottom: 3px solid $white;
      display: inline-block;
    }
    &.darker-color {
      span {
        border-bottom-color: $accent-color;
        display: inline-block;
      }
    }
  }
  .section-title {
    span {
      font-weight: $medium;
    }
  }
  .section-title-moredes {
    font-size: 20px;
    line-height: 1.6;
    color: #f2f2f2;
    &.darker-color {
      color: #112631;
    }
  }
  .section-title-moredes-style-2 {
    color: #f2f2f2;
  }
  .quote {
    font-size: 20px;
    line-height: 1.5;
    color: $white;
    span{
      font-family: Impact;
      font-size: 100px;
      padding-right: 15px;
      position: relative;
      top: 45px;
      color: $theme-color;
    }
  }
  .author {
    color: #ccc;
  }
  .tparrows.tp-leftarrow, .tp-rightarrow.tparrows {
    width: 50px;
    height: 50px;
    text-align: center;
    background: $accent-color;
    &:before {
      font-family: FontAwesome;
      font-size: 20px;
      line-height: 50px;
      color: $theme-color;
    }
  }
  .tp-leftarrow:before {
    content: '\f060';
  }
  .tp-rightarrow:before {
    content: '\f061';
  }

}
@include  respond-to('medium-mobile') {
  .rev_slider {
    .tparrows.tp-leftarrow, .tp-rightarrow.tparrows {
      display: none;
    }
    .aubtn {
      line-height: 35px;
      font-size: 13px;
    }
    .quote span {
      font-size: 50px;
      top: 20px;
    }
  }

}