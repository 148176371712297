.contact {
  margin-bottom: 60px;
  .section-title {
    font-weight: $bold;
  }
}
// Contact info
.contact-info-wrapper {

}
.contact-info-title {
  color: $grey-4;
  margin-bottom: 40px;
}
.contact-info {
  border: 1px solid #e5e5e5;
  padding: 20px 20px 0 40px;
  margin-bottom: 70px;
}
.contact-info-item {
  padding: 20px 15px 20px 45px;
  .contact-info-item-icon {
    font-size: 33px;
    color: $theme-color;
    margin-left: -45px;
    float: left;
  }
  .contact-info-item-content {
    font-size: 16px;
    line-height: 1.25;
    font-weight: $bold;
    color: $grey-1;
    margin-top: 5px;
  }
}
// Feedback form
.feedback-form-wrapper {
  padding-right: 35px;
  margin-bottom: 40px;
  .section-title {
    font-weight: $bold;
    margin-bottom: 25px;
  }
  input, textarea {
    background: #ededed;
    border: 1px solid #dedede;
    border-radius: 3px;
    color: $grey-1;
   }
  ::-webkit-input-placeholder {
    color:    $grey-4;
  }
  :-moz-placeholder {
    color:    $grey-4;
    opacity:  1;
  }
  ::-moz-placeholder {
    color:    $grey-4;
    opacity:  1;
  }
  :-ms-input-placeholder {
    color:    $grey-4;
  }
  .message {
    height: 201px;
  }
  .aubtn {
    margin: 12px 0 0 0;
    input {
      padding: 0 50px;
      color: $white;
    }
    &:hover {
      input {
        color: $theme-color;
      }
    }
  }
}

#map {
  height: 411px;
}

// Responsive
@include respond-to('medium-tablet') {
  .feedback-form-wrapper {
    padding-right: 0;
  }
  .contact-map {
    padding: 20px 0;
  }
}

@include respond-to('medium-mobile') {
  .contact-info {
    padding: 20px 15px 30px;
    .contact-info-item {
      padding-right: 0;
      .contact-info-item-content {
        font-weight: $medium;
      }
    }
  }
}